import HomeClaimImg from '../../assets/kibukibu_slogan.svg'
import './homeClaim.scss'

export const HomeClaim = () =>
    <div className="homeClaimContainer">
        <div className='homeClaim'>
            <div className="homeClaimText">Il primo motore di ricerca di modellini, robot e action figures in Italia!</div>
            <div className="homeClaimImage">
                <img src={HomeClaimImg} alt="Cerca, trova e risparmi" />
            </div>
        </div>
    </div>