import { useRecoilValue } from 'recoil'
import { Breadcrumb } from '../components/Breadcrumb/Breadcrumb'
import { ListingContent } from '../components/ListingContent/ListingContent'
import {getCurrentSearch} from "../atoms/dataAtom";

const Listing = () =>
    {
        const search = useRecoilValue(getCurrentSearch)?.filter
        return <>
            <Breadcrumb crumbs={["Home", search]} />
            <ListingContent />
        </>
    }

export { Listing }