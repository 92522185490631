import React from "react";
import {useRecoilValue} from "recoil";
import {dataAtom} from "../../atoms/dataAtom";
import './categoriesList.scss'

const CategoriesList: React.FC = () => {
    const data = useRecoilValue(dataAtom)
    const categories = data?.categories ?? []
    return <div className="categoriesContainer">
        <h2>Tutte le nostre categorie</h2>
        <div className="categories">
            {categories.map(c =>
                <div className="category" key={c.name}>
                    <a href={`/category/${c.slug}.html`}>{c.name}</a>
                </div>
            )}
            <div className="category"></div>
        </div>
    </div>
}

export {CategoriesList}