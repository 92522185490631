import {LegalContainer} from "../components/LegalContainer/LegalContainer";
import {Link} from "react-router-dom";


export const TermsAndConditions = () => <>
    <LegalContainer>
        <div className="container">
            <h3>Condizioni generali relative all’utilizzo del Sito Web “www.kibukibu.com”</h3>
            Questo sito web (il "Sito") è di proprietà di Alessandro Sangalli, C.F. SNGLSN80S30F704M ed Alberto Noseda,
            C.F. NSDLRT75P28C933G (di seguito: "Kibukibu" o "noi").
            Si prega di tenere conto del fatto che l'accesso e l'utilizzo del Sito (voi come "Utente/i") richiede
            l'accettazione dei seguenti termini e condizioni ("T&Cs"). Pertanto, laddove l’Utente continui ad accedere
            e/o ad utilizzare il Sito, si ritiene che quest’ultimo abbia accettato i seguenti T&Cs.
            Kibukibu si riserva il diritto di modificare i T&Cs in qualsiasi momento e pubblicherà qui eventuali
            variazioni degli stessi. Si consiglia pertanto all'Utente di controllare regolarmente i T&Cs pubblicati sul
            Sito in quanto si ritiene che lo stesso abbia accettato le variazioni dei suddetti T&Cs laddove questi
            continui ad utilizzare il Sito dopo la loro pubblicazione.
            Qualora l’Utente non accetti di rispettare questi T&Cs, lo stesso non dovrebbe utilizzare in alcun modo il
            Sito.<br/><br/>

            <b>1. Utilizzo del Sito Web</b><br/>
            L'Utente può visitare liberamente il Sito ed utilizzare le sue funzionalità conformemente alle seguenti
            condizioni.<br/>
            Questo Sito mette a disposizione degli Utenti un servizio di ricerca e di comparazione di prodotti (in
            particolare giocattoli da collezione, di seguito anche “Prodotti”) offerti da venditori terzi (di seguito
            denominati collettivamente “Servizi”).<br/><br/>

            <b>2. Regole di condotta</b><br/>
            L'Utente si impegna ad utilizzare il Sito, le sue funzionalità ed i Servizi per finalità legittime e nel
            rispetto dei presenti T&Cs. In particolare, l'Utente si impegna a non inviare o diffondere attraverso il
            Sito e le sue funzionalità contenuti di qualsiasi natura che siano o possano venire percepiti come illeciti,
            diffamatori, volgari, osceni, abusivi o comunque molesti e si impegna altresì a non utilizzare il Sito e le
            sue funzionalità per compiere atti che possano in qualche modo arrecare un qualsiasi pregiudizio e danno,
            anche d'immagine e di reputazione, a Kibukibu.
            Inoltre, l'Utente si impegna a non inviare, trasmettere o diffondere materiali e contenuti di natura
            pubblicitaria e promozionale e/o non richiesti o non sollecitati, che possano interferire o arrecare
            pregiudizio alle funzionalità del Sito Web e dei Servizi o all'utilizzo del Sito Web e dei Servizi da parte
            di terzi. <br/><br/>

            <b>3. Proprietà intellettuale</b><br/>
            L'Utente prende atto e riconosce che tutte le informazioni, dati, software, contenuti (inclusi gli articoli
            mostrati nella Sezione "Blog" del Sito Web) ed ogni altro contenuto presente sul Sito (i "Contenuti") sono
            di esclusiva titolarità di Kibukibu e/o dei suoi licenzianti e/o dei venditori terzi i cui Prodotti sono
            mostrati sul Sito.
            L'Utente non ha né acquisisce alcun diritto su tali Contenuti e/o sulle proprietà del Sito, salvo il diritto
            ad utilizzare il Sito ai sensi dei presenti T&Cs.
            Il copyright sul design e sull'architettura del Sito è di proprietà di Kibukibu. Quest’ultima è proprietaria
            dei diritti d'autore sui Contenuti pubblicati sul Sito, salvo ove diversamente indicato da un avviso
            relativo a diritti di proprietà di terzi.
            L'Utente è autorizzato a stampare e/o scaricare i Contenuti (inclusi i T&Cs) solamente per un utilizzo
            personale.<br/><br/>

            <b>4. Informativa sulla privacy e sul trattamento dei dati personali</b><br/>
            Le informazioni relative alle modalità con cui Kibukibu tratta i dati personali e all'utilizzo dei cookie
            sul Sito sono riportate rispettivamente nella nostra <Link to="/legal/privacy-policy">Informativa
            sulla Privacy</Link> e nell'<Link to="/legal/cookie-policy">Informativa sui Cookie</Link>.<br/><br/>

            <b>5. Informazioni relative ai Servizi e ai Contenuti offerti attraverso il Sito </b><br/>
            Le informazioni pubblicate da Kibukibu sul Sito sono fornite da Kibukibu e, ove indicato, da alcune terze
            parti (tra cui in particolare i venditori terzi i cui Prodotti sono mostrati sul Sito). Qualsiasi
            riferimento a Servizi che siano stati o possano essere forniti da Kibukibu o da un partner di quest’ultimo,
            non equivale alla garanzia che tali Servizi saranno disponibili in qualunque momento. Modifiche o
            miglioramenti a tali Servizi possono essere effettuati in qualsiasi momento senza preavviso.
            Si prega di notare che le immagini e/o informazioni relative ai Prodotti offerti dai venditori terzi e
            mostrate sul Sito (in particolare nella pagina dei risultati di ricerca) non sono contenuti prodotti da
            Kibukibu e/o di proprietà di quest’ultimo; pertanto Kibukibu – non avendo alcun diritto e/o obbligo su tali
            contenuti – non potrà in nessun caso essere considerata responsabile nei confronti dell’Utente o di terzi
            per qualsiasi danno diretto o indiretto derivante dalla violazione di diritti di proprietà intellettuale o
            dalla violazione degli obblighi dei suddetti terzi ai sensi di qualsiasi legge, norma e regolamento
            applicabile.
            Tuttavia, laddove l'Utente e/o terze parti ritengano che le suddette immagini e/o contenuti mostrati sul
            Sito violino i loro diritti di proprietà intellettuale, potranno contattare direttamente Kibukibu scrivendo
            a user.support@kibukibu.com al fine di chiederne la rimozione immediata dal Sito.<br/><br/>

            <b>6. Link ad altri siti web</b><br/>
            Il Sito può contenere link ipertestuali ad altri siti web di partner commerciali di Kibukibu (tra cui i
            venditori terzi i cui Prodotti sono mostrati sul Sito) e/o di terze parti.
            La pubblicazione dei link ipertestuali sul Sito avviene semplicemente per consentire un più facile
            reperimento informativo di altre risorse o siti web per l'Utente e non implica nessuna valutazione, esame,
            validazione o approvazione da parte di Kibukibu dei contenuti di tali siti web terzi e dei contenuti in
            questo modo reperibili. Kibukibu non effettua alcun controllo sui contenuti, sulle informazioni e sui
            servizi o prodotti offerti tramite questi siti web terzi. Kibukibu non è responsabile del contenuto di tali
            siti web terzi ai quali l'Utente può collegarsi tramite un hyperlink dal Sito e non assume alcuna
            responsabilità circa l'affidabilità, veridicità, accuratezza e aggiornamento dei contenuti e informazioni
            presenti in questi siti web terzi. L'Utente riconosce che l'uso che farà di tali siti web terzi rientra
            esclusivamente sotto il proprio esclusivo dominio e responsabilità e avviene a proprio rischio e pericolo.
            La pubblicazione dei link ipertestuali o di altre risorse Internet sul Sito non comporta nessun tipo di
            affiliazione o associazione commerciale o collaborazione tra Kibukibu e i soggetti titolari di tali siti web
            terzi.<br/><br/>

            <b>7. Scelta della legge applicabile e giurisdizione esclusiva</b><br/>
            Nel caso di professionisti e aziende<br/>
            I T&Cs e la relazione tra l'Utente e Kibukibu sono disciplinati, nella misura consentita dalla normativa
            applicabile, dalla legge italiana. Per quanto consentito dalla normativa applicabile e dagli accordi
            internazionali, le parti convengono che per ogni questione relativa all'interpretazione, al rispetto o
            all'esecuzione dei presenti T&Cs, la risoluzione di qualsiasi controversia sarà di esclusiva competenza dei
            tribunali ordinari del luogo in cui è situata la sede di Kibukibu, rinunciando espressamente a qualsiasi
            altra giurisdizione, sempre che la normativa applicabile non stabilisca una specifica giurisdizione
            obbligatoria.<br/>
            Nel caso di consumatori<br/>
            I presenti T&Cs sono regolati dal diritto italiano o, nel caso in cui l'Utente risieda in un paese diverso
            dall’Italia, dalla legge del luogo di residenza o domicilio dell’Utente/consumatore. I tribunali del luogo
            di residenza o domicilio del consumatore sono competenti per qualsiasi controversia derivante dai presenti
            T&Cs. Nei casi in cui la legge non preveda necessariamente la residenza o il domicilio del consumatore per
            risolvere i conflitti relativi ai presenti T&Cs, sarà competente, a scelta del consumatore, il tribunale del
            luogo di residenza o domicilio del consumatore o il tribunale del luogo in cui è situata la sede legale di
            Kibukibu. Si prega di notare inoltre che la Commissione Europea mette a disposizione di tutti i cittadini
            dell'Unione una piattaforma per la risoluzione online delle controversie in relazione ai servizi forniti da
            società appartenenti all'Unione Europea (la "Piattaforma ODR"), accessibile attraverso il seguente link:
            http://ec.europa.eu/consumers/odr.
            In ogni caso, si prega di notare che nella misura in cui una qualsiasi parte e/o disposizione dei presenti
            T&Cs sia ritenuta invalida, illecita o inapplicabile da qualsiasi tribunale giurisdizionalmente competente,
            tale parte e/o disposizione sarà in tal senso esclusa dai restanti termini, condizioni e disposizioni, che
            continueranno ad essere pienamente validi ed efficaci nella misura consentita dalla legge.<br/>
        </div>
    </LegalContainer>
</>
