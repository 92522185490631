import Face4 from '../../assets/face4.png'
import { GrFacebookOption, GrInstagram } from 'react-icons/gr'

import "./footerSocial.scss"

export const FooterSocial = () =>
    <div className="footerSocial">
        <div className="footerSocialBox">
            <img src={Face4} alt="" />
        </div>
        <div className="footerSocialBox">seguici su</div>
        <div className="footerSocialBox footerSocialBoxIconsContainer">
            <div className="socialIcon">
                <a href="https://www.facebook.com/kibukibucom" target="_blank" rel="noreferrer"><GrFacebookOption color='#F257A2' /></a>
            </div>
            <div className="socialIcon">
                <a href="https://www.instagram.com/kibukibucom/" target="_blank" rel="noreferrer"><GrInstagram color='#F257A2' /></a>
            </div>
        </div>
    </div>