import image from '../../assets/action_figure_telegram.png'

import './communityAdv.scss'

export const CommunityAdv = () =>
    <div className="communityAdvContainer">
        <div className="communityAdvImage">
            <a href="https://t.me/ActionFiguresItaliaGroup" target="_blank" rel="noreferrer">
                <img src={image} alt="Action figures Italia" />
            </a>
        </div>
        <div className="communityAdvText">
            <a href="https://t.me/ActionFiguresItaliaGroup" target="_blank" rel="noreferrer">
                <div className="communityAdvTextContent">
                    <div className="firstLine">Non sai con chi parlare di collezionismo?</div>
                    <div className="secondLine">Entra nella community di telegram</div>
                </div>
            </a>
        </div>
    </div>