import React from "react";
import { goToHome } from '../../util/global'
import './breadcrumb.scss'

const Breadcrumb: React.FC<{ crumbs: (string | undefined)[] }> = ({ crumbs }) =>
    {
        const onCrumbClick = (idx: number) => {
            if (idx === 0) goToHome()
        }
        return <div className='breadcrumbContainer'>
            {crumbs
                .filter(c => !!c)
                .map((c, i) => <span key={c} onClick={() => onCrumbClick(i)} className='breadcrumb'>{c} {i !== crumbs.length - 1 ? <>&raquo; </> : null}</span>)}
        </div>
    }

export { Breadcrumb }