import React from 'react'
import { Outlet } from 'react-router'
import { Footer } from '../components/Footer/Footer'
import { Header } from '../components/Header/Header'

const Layout = () => <>
    <Header></Header>
    <Outlet />
    <Footer></Footer>
</>

export { Layout }