import React from "react";
import {ContactUs} from "../components/ContactUs/ContactUs";
import {Breadcrumb} from "../components/Breadcrumb/Breadcrumb";

export type ContactData = {
    type: string,
    name: string,
    shop: string,
    shopUrl: string,
    email: string,
    message: string
}

const validate = (data: ContactData): boolean => {
    if (data.type === "customer") {
        return !!data.email && !!data.message && !!data.name && /^.+@[^.].*\.[a-z]{2,}$/.test(data.email);
    } else {
        return !!data.email && !!data.message && !!data.name && !!data.shop && /^.+@[^.].*\.[a-z]{2,}$/.test(data.email);
    }
}

const onContact = async (data: ContactData): Promise<boolean> => {
    try {
        const response = await fetch("/api/v1/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        return response.ok
    } catch (e) {
        return false
    }
}

export const Contact: React.FC<{}> = () => <>
    <Breadcrumb crumbs={["Home", "contattaci"]} />
    <ContactUs onContact={onContact} validate={validate}/>
</>