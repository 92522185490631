import "./categoryContent.scss"
import {useRecoilState} from "recoil";
import {dataAtom} from "../../atoms/dataAtom";

const CategoryContent = () => {
    const [data,] = useRecoilState(dataAtom)

    if (!data?.categoryContent) return null

    return <div className="categoryContentContainer">
        <h1>{data.categoryContent.category}</h1>
        <div dangerouslySetInnerHTML={{__html: data.categoryContent.content}}></div>
        {data.categoryContent.wiki ?
            <div className={"wikiLinkContainer"}>
                <a href={data.categoryContent.wiki} target="_blank" rel="noreferrer" className={"wikiLink"}>
                    Wiki link
                </a>
            </div>
            : null
        }
    </div>
}

export { CategoryContent }