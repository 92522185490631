import {Toy} from '../../atoms/dataAtom';
import './detailToy.scss'
import React, {Suspense} from "react";
import {DetailToyPrices} from "../DetailToyPrices/DetailToyPrices";
import {convertHtmlEntities} from "../../util/global";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";


const DetailToy: React.FC<{ toy?: Toy }> = ({toy}) => {
    const images = [...new Set(toy?.images)]
    const [galleryOpen, setGalleryOpen] = React.useState(false)
    return (
        <div className='toyExternalContainer'>
            <div className="toyContainer">
                <div className="toyImage">
                    <div className="toyTitleMobile">
                        {convertHtmlEntities(toy?.name)}
                    </div>
                    <img src={toy?.images[0]} alt={convertHtmlEntities(toy?.name)} onClick={() => setGalleryOpen(true)}/>
                    <div className="toyImageClickToZoom" onClick={() => setGalleryOpen(true)}>Clicca sull'immagine per vederle tutte</div>
                    <Lightbox
                        open={galleryOpen}
                        plugins={[Thumbnails]}
                        slides={images.map(t => ({src: t}))}
                        close={() => setGalleryOpen(false)}
                    />
                </div>
                <div className="toyDescription">
                    <div className="toyTitle">
                        {convertHtmlEntities(toy?.name)}
                    </div>
                    <div className="toyText" dangerouslySetInnerHTML={{__html: toy?.description ?? ""}}></div>
                    <Suspense fallback={null}>
                        <DetailToyPrices toy={toy}/>
                    </Suspense>
                </div>
            </div>
        </div>
    );
}

export {DetailToy}