import {Toy} from '../../atoms/dataAtom'
import './detailToyInfo.scss'
import {Label} from "../Label/Label";
import React from "react";
import {formatPrice} from "../../util/global";

const DetailToyInfo:React.FC<{toy: Toy, flags: Record<string, boolean>}> = ({toy, flags}) =>
    {
        return <div className="detailToyInfoContainer">
            <div className="detailToyInfoStore">
                <div className="detailToyInfoStoreLabel">Negozio</div>
                <div className="detailToyInfoStoreName">{toy.merchant}</div>
            </div>
            <div className="detailToyInfoPrice">
                <div className="detailToyInfoPriceLabel">Prezzo</div>
                <div className="detailToyInfoPriceValue">
                    {formatPrice(toy.price)}
                </div>
                {flags["CONVERSION"] ? <div className="detailToyInfoConversionDisclaimer">
                    Il prezzo visualizzato &egrave; stato convertito automaticamente al tasso del giorno; potrebbe presentare variazioni rispetto al negozio del venditore.
                </div> : null}
            </div>
            <div className="detailToyActions">
                <div className={`detailToyActionsButton ${toy.availability.toLowerCase()}`}><Label keyRef={`toy.status.${toy.availability}`} /></div>
                <a className="detailToyActionsButton detailToyActionsStore" href={`/redirect/${toy.id}`} target="_blank" rel="noopener">Vai al Negozio</a>
            </div>
        </div>
    }

export { DetailToyInfo }