import { useRecoilState, useRecoilValue } from 'recoil'
import {dataAtom, getCurrentSearch, getTotalPages} from '../../atoms/dataAtom'
import { getEndPage, getStartPage } from '../../atoms/searchAtom'
import {gotoSearch} from '../../util/global'
import { Filters } from '../Filters/Filters'
import { ListingItem } from '../ListingItem/ListingItem'
import NoResults from '../../assets/oops.png'
import './listingContent.scss'
import {CategoryContent} from "../CategoryContent/CategoryContent";

const ListingWithResults = () => {
    const [data,] = useRecoilState(dataAtom)
    const totalPages = useRecoilValue(getTotalPages)
    const startPage = useRecoilValue(getStartPage)
    const endPage = useRecoilValue(getEndPage)
    const search = useRecoilValue(getCurrentSearch)

    const currentPage = search.page
    const showMorePages = totalPages !== endPage
    const showFirstPageShortcut = startPage > 1
    const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)

    const AdvListing = () => <div className={"advContainer advContainerMobile"}>
        <a rel="sponsored" href="https://www.awin1.com/cread.php?s=3577397&v=25709&q=484252&r=1541567">
            <img src="https://www.awin1.com/cshow.php?s=3577397&v=25709&q=484252&r=1541567" alt={"ad"}/>
        </a>
    </div>

    const onPageClick = (page: number) => { if (page !== currentPage) gotoSearch({...search, page}) }

    return <>
        <CategoryContent />
        <div className="listingPaginationDescription">
            Risultati da <b>{(currentPage - 1) * 10 + 1}</b> a <b>{Math.min(currentPage * 10, data?.total ?? 0)}</b> su <b>{data?.total}</b> per "{search.filter}"
        </div>
        {data?.toys?.map((t, i) =>
            <span key={t.id}>
                <ListingItem element={t} />
                {(i === 1 || data?.toys?.length === 1) ? <AdvListing /> : null}
            </span>
        )}
        <div className="listingPagination">
            {currentPage > 1 ? <div className="listingPaginationPage" onClick={() => onPageClick(currentPage - 1)}>&lt;</div> : null}
            {showFirstPageShortcut ? <><div className="listingPaginationPage" onClick={() => onPageClick(1)}>1</div><div className="listingPaginationPage">...</div></> : null}
            {pages.map(p => <div className={`listingPaginationPage${p === currentPage ? " listingPaginationPageActive" : ""}`} key={p} onClick={() => onPageClick(p)}>{p}</div>)}
            {showMorePages ?
                <>
                    <div className="listingPaginationPage">...</div>
                    <div className="listingPaginationPage" onClick={() => onPageClick(totalPages)}>{totalPages}</div>
                </> :
                null
            }
            {currentPage < totalPages ? <div className="listingPaginationPage" onClick={() => onPageClick(currentPage + 1)}>&gt;</div> : null}
        </div>
    </>
}

const ListingNoResults = () => <div className='listingPaginationDescription listingNoResults'><img src={NoResults} alt="There are no results"/></div>


const ListingContent = () => {
    const [data,] = useRecoilState(dataAtom)
    const anyResult = (data?.total ?? 0) > 0

    return <div className='listingContentContainer'>
        <div className="listingContentSidebar">
            <Filters />
        </div>
        <div className="listingContentMain">
            {anyResult ? <ListingWithResults /> : <ListingNoResults />}
        </div>
    </div>
}

export { ListingContent }