import React from "react";
import {useRecoilValue} from "recoil";
import {dataAtom} from "../../atoms/dataAtom";
import "./homeCategories.scss"

const HomeCategories: React.FC = () => {
    const data = useRecoilValue(dataAtom)
    const categories = data?.categories?.slice(0, 30) ?? []
    return <div className="homeCategoriesContainer">
        <div className="homeCategoriesTitle">
            <h2>I pi&ugrave; ricercati</h2>
            <a href="/category">+ Mostra tutto</a>
        </div>
        <div className="homeCategoriesList">
            {categories.map((c, i) => <div className={"homeCategory" + (i >= 15 ? " noMobile" : "")} key={c.name}>
                <a href={`/category/${c.slug}.html`}>{c.name}</a>
            </div>)}
        </div>
    </div>;
}

export {HomeCategories}