import {Search} from "../atoms/searchAtom";

export const defaultSearch = {
    filter: "",
    fromPrice: "1",
    toPrice: undefined,
    availability: ["ALL"],
    merchant: ["ALL"],
    page: 1,
    orderBy: "PRICE",
    orderByDirection: "ASC"
} as Search

export const gotoSearch = (search: Search, newFilter: string = search.filter) => {
    const searchBits: Record<string, string | undefined | number | string[]> = {
        ...search,
        filter: newFilter.trim()
    }
    const queryBit = Object
        .keys(searchBits)
        .filter(k => !!searchBits[k])
        // @ts-ignore
        .filter(k => searchBits[k]?.toString() !== defaultSearch[k]?.toString())
        // @ts-ignore
        .map(k => `${k}=${k === "merchant" ? searchBits[k]?.join(",") : searchBits[k]}`).join("&")
    window.location.href = `/listing?${queryBit}`
}

export const gotoFirstPageSearch = (
    search: Search,
    newFilter: string = search.filter,
    shouldReset: boolean = false
) => gotoSearch(shouldReset ? {...defaultSearch, filter: newFilter} : {
    ...search,
    page: 1
}, newFilter)

export const goToHome = () => (window.location.href = "/")

export const goToToy = (toy: string, isLink: boolean = false) => (window.location.href = isLink ? toy : `/detail/${toy}`)
export const goToLink = (link: string) => window.open(link)


const euroFormatter = Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: false,
});
export const formatPrice = (price: number) => euroFormatter.format(price)

export const convertHtmlEntities = (value?: string) => {
    if (!value) return ""
    const container = document.createElement("div")
    container.innerHTML = value
    return container.innerText
}