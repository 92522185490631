import React from 'react'
import { FooterClaim } from '../FooterClaim/FooterClaim'
import { FooterLegal } from '../FooterLegal/FooterLegal'
import { FooterSocial } from '../FooterSocial/FooterSocial'
import './footer.scss'

const Footer = () => <>
    <div className="footerContainer">
        <div className="footer">
            <div className="footerBox">
                <FooterClaim />
            </div>
            <div className="footerBox">
                <FooterSocial />
            </div>
            <div className="footerBox">
                <FooterLegal />
            </div>
        </div>
    </div>
</>

export { Footer }