import {LegalContainer} from "../components/LegalContainer/LegalContainer";
import {Link} from "react-router-dom";


export const CookiePolicy = () => <>
    <LegalContainer>
        <div>
            <h1>INFORMATIVA SUI COOKIE</h1>
            1. Introduzione<br/>
            2. Chi è il titolare del trattamento dei dati?<br/>
            3. Che cosa sono i Cookie e altre tecnologie simili?<br/>
            4. Autorizzazione per l'utilizzo dei Cookie nel Sito<br/>
            5. Quali categorie di dati vengono raccolte ed utilizzate dal Titolare del trattamento<br/>
            6. Tipologie di Cookie utilizzati nel Sito<br/>
            7. Elenco dei Cookie utilizzati nel Sito<br/>
            8. Perché vengono raccolti questi dati?<br/>
            9. Per quanto tempo vengono conservati i dati?<br/>
            10. Gestione dei Cookie<br/>
            11. Dati di contatto del titolare del trattamento e i diritti dell’Utente alla protezione dei suoi dati
            personali<br/><br/>
            <b>1. Introduzione</b><br/>
            La presente Informativa sui Cookie fornisce informazioni sui cookie ed altre tecnologie di tracciamento
            similari (di seguito, collettivamente definiti “Cookie”) utilizzati nel presente Sito web (di seguito:
            “Sito”), in modo da consentire all’Utente di comprendere meglio l’utilizzo dei Cookie durante la navigazione
            e di fornire il proprio consenso a tale scopo.<br/>
            Su questo Sito vengono utilizzati Cookie al fine di rendere più semplice l’utilizzo dello stesso nonchè per
            adattare sia il Sito che i servizi ivi forniti agli interessi e bisogni dell’Utente. I Cookie possono anche
            essere impiegati per velocizzare e migliorare le future esperienze ed attività dell’Utente sul
            Sito.<br/><br/>
            <b>2. Chi è il Titolare del trattamento dei dati? </b><br/>
            Laddove all’interno della presente Informativa sui Cookie siano presenti i termini “Titolare del
            trattamento” o “suo/suoi”, essi si intendono fare riferimento a:<br/>
            Alessandro Sangalli, C.F. SNGLSN80S30F704M ed Alberto Noseda, C.F. NSDLRT75P28C933G, i quali sono titolari
            del trattamento dei dati personali degli Utenti ai sensi della presente Informativa sui Cookie.<br/><br/>
            <b>3. Che cosa sono i Cookie e altre tecnologie simili?</b><br/>
            I Cookie sono piccole stringhe di testo archiviate su computer, disco fisso, smartphone o tablet (di
            seguito: “Dispositivo”) quando si visita un sito web.<br/>
            I Cookie contengono una modesta quantità di dati specifici dell’Utente e permettono ad un server di proporre
            una pagina personalizzata sul Dispositivo dell’Utente.<br/>
            Sul Sito il Titolare del trattamento potrebbe utilizzare anche tecnologie simili definite “tag” (come ad
            esempio “tracking pixel” o “script”), “HTML5 local storage”, “Web beacon” o “plug-in” oltre a “kit di
            sviluppo software” (SDK) e tecniche di riconoscimento delle impronte digitali, ovvero tecnologie che
            consentono di archiviare o accedere alle informazioni sul Dispositivo dell’Utente in modo da identificare
            quest’ultimo per analizzarne le visite a un sito web. Queste tecnologie possono inoltre essere utilizzate
            insieme ai Cookie per consentire l’archiviazione di informazioni sul Dispositivo utilizzato dall’Utente per
            accedere al Sito, oppure la trasmissione da e verso di questo.<br/>
            In aggiunta, implementando i Cookie, qualora l’Utente faccia ritorno sul Sito, quest’ultimo potrà leggere e
            riconoscere tali tecnologie, principalmente utilizzate per l’operatività del Sito o per migliorare il
            funzionamento dello stesso, nonché per fornire informazioni commerciali e di marketing al proprietario del
            Sito. In particolare, ad ogni visita successiva i Cookie vengono inviati al sito web che li ha originati
            (first-party cookies) o ad un altro sito web che li riconosce (third-party cookies).<br/>
            I Cookie sono utili perché consentono a un sito web di riconoscere il Dispositivo dell'Utente. Essi hanno
            scopi diversi come, ad esempio, consentire di navigare in modo efficiente tra le pagine, ricordare i siti
            preferiti dall’Utente e, in generale, migliorare l'esperienza di navigazione di quest’ultimo. Inoltre, i
            Cookie contribuiscono a garantire che il contenuto pubblicitario visualizzato online sia più mirato agli
            interessi dell’Utente.<br/><br/>
            <b>4. Autorizzazione per l'utilizzo dei Cookie nel Sito</b><br/>
            In conformità alla nota sull’utilizzo dei Cookie presente nella homepage del Sito, l’Utente cliccando sul
            pulsante “Accetta” del Cookie banner e continuando la navigazione sul Sito acconsente all’installazione e
            all’utilizzo dei Cookie ivi descritto, a meno che l’Utente non abbia previamente impostato le proprie
            preferenze cookie nella sezione dedicata del Cookie banner (“Preferenze cookie”) o non abbia modificato le
            impostazioni del browser per disabilitarne l’utilizzo. Si prega di notare, tuttavia, che la disattivazione
            dei Cookie utilizzati dal Titolare del trattamento, potrebbe influenzare una miglior user experience
            dell’Utente durante la navigazione e/o utilizzo del Sito.<br/><br/>
            <b>5. Quali categorie di dati vengono raccolte ed utilizzate dal Titolare del trattamento?</b><br/>
            Qualora l’Utente visiti il Sito e utilizzi il servizio di ricerca e comparazione, il Titolare del
            trattamento raccoglie le seguenti categorie di dati personali:
            informazioni relative alle visite al Sito, all’utilizzo del Sito e/o dei servizi offerti dal Titolare del
            trattamento, quali ad esempio il dispositivo e il browser utilizzati dall’Utente, l’indirizzo IP o i nomi
            dominio dei computer collegati al Sito, gli indirizzi in notazione URI delle richieste effettuate, l’orario
            della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto
            in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.)
            ed altri parametri relativi al sistema operativo e all’ambiente informatico utilizzato dall’Utente, quali la
            data e l’orario della visita, la durata della visita, il sito di rinvio e il percorso di navigazione sul
            Sito relativo alla visita e alle interazioni sul Sito stesso, inclusi i servizi e le offerte a cui l’Utente
            è interessato.<br/>
            Per ulteriori informazioni sugli scopi per i quali il Titolare del trattamento raccoglie ed utilizza queste
            informazioni, si veda il seguente Articolo 6.<br/><br/>
            <b>6. Tipologie di Cookie utilizzati nel Sito</b><br/>
            <p>
                <b>6.1 Tipologie di Cookie secondo l’ente di gestione</b><br/>
                In base all’ente che gestisce il Sito o il dominio dai quali sono impostati e trattati i Cookie,
                esistono le seguenti tipologie di Cookie:<br/>
                Cookie proprietari: vengono inviati al
                Dispositivo dell’Utente da un sito web o un dominio gestiti dal Titolare del trattamento e dai quali
                viene fornito il servizio richiesto dall’Utente.<br/>
                Cookie di terze parti: vengono inviati al
                Dispositivo dell’Utente da un sito web o un dominio diversi da quelli visitati e non gestiti dal
                Titolare del trattamento, bensì da un’entità separata che tratta i dati ottenuti attraverso i
                Cookie.<br/>
                <b>6.2 Tipologie di Cookie secondo la durata di tempo per il quale si rimane connessi </b>
                In base a quanto tempo l’Utente rimane attivo sul proprio Dispositivo, vi sono le seguenti tipologie di
                Cookie:<br/>
                Cookie di sessione: hanno la funzione di
                ricevere e archiviare dati quando l’Utente effettua l’accesso al Sito. Tali Cookie non rimangono
                archiviati nel Dispositivo quando si termina la sessione nel browser o si esce dal Sito.<br/>
                Cookie persistenti: i Cookie di questo
                tipo rimangono archiviati sul Dispositivo dell’Utente, sono accessibili e possono essere trattati sia
                dopo che l’Utente ha abbandonato il Sito, sia quando naviga all’interno dello stesso per un determinato
                periodo di tempo. Tali Cookie rimangono sul disco fisso fino al raggiungimento della loro data di
                scadenza. In ogni caso, si prega di notare che utilizzeremo i Cookie persistenti sul nostro sito Web per
                un periodo massimo di 12 mesi. Successivamente, il browser eliminerà tali Cookie dal disco fisso.<br/>
                <b>6.3 Tipologie di Cookie e altre tecnologie simili secondo la loro finalità</b><br/>
                I Cookie e altre tecnologie simili (collettivamente “Cookie”) possono essere raggruppati come
                segue:<br/>
                Cookie tecnici: tali Cookie sono
                strettamente necessari per il funzionamento del Sito, sono essenziali per la navigazione e consentono
                l’utilizzo di varie funzioni dello stesso. Senza di essi non è possibile utilizzare la funzione di
                ricerca, nè fruire di altri servizi disponibili sul Sito (tra cui il servizio di comparazione). Questi
                cookie sono utilizzati dal Titolare dal trattamento anche per misurare le statistiche di traffico, per
                rilevare problemi di navigazione e organizzare il contenuto del Sito nonchè per motivi di
                sicurezza.<br/>
                Cookie di personalizzazione, pubblicitari
                e di remarketing: questi Cookie vengono utilizzati per diversi scopi, quali la raccolta e l’elaborazione
                delle informazioni correlate all’utilizzo da parte dell’Utente dei servizi e/o dei contenuti mostrati
                sul Sito, nonché alla sua interazione con le inserzioni pubblicitarie, ad esempio facendo clic su un
                banner pubblicitario o su uno specifico risultato di ricerca; la creazione di un profilo dell'Utente e
                dei suoi interessi; per mostrare all’Utente stesso annunci e/o contenuti personalizzati sul Sito o in
                altri contesti, come su altri siti web o applicazioni mobile. Si prega di notare che i contenuti del
                Sito vengono utilizzati per desumere gli interessi dell’Utente, i quali saranno poi analizzati ai fini
                della selezione di annunci e contenuti futuri. A tale scopo, il Titolare del trattamento potrebbe anche
                avvalersi di servizi forniti da terzi in suo favore al fine di raccogliere dati e/o pubblicare
                inserzioni pubblicitarie durante le visite dell’Utente al Sito, al fine di mostrare a quest’ultimo
                annunci relativi a beni e servizi di suo interesse.<br/>
                Cookie statistici e di misurazione: tali
                Cookie vengono installati sul Sito dal Titolare del trattamento o da terze parti al fine di raccogliere
                informazioni sull’utilizzo che viene fatto da parte dell’Utente dei contenuti del Sito stesso e sulle
                pagine visitate. Il Titolare del trattamento utilizza inoltre tali Cookie per riconoscere il luogo di
                origine delle visite al Sito. Le informazioni raccolte tramite questi Cookie possono essere poi
                combinate con dati raccolti in precedenza allo scopo di monitorare ed analizzare il comportamento
                dell’Utente sul Sito. Tali Cookie non sono cookie di profilazione, ossia cookie che raccolgono
                informazioni sull'utilizzo del Sito da parte dell’Utente al fine di mostrare successivamente a
                quest’ultimo contenuti e/o annunci pubblicitari personalizzati in altri contesti. Un esempio di questi
                Cookie sono quelli installati attraverso il servizio di <a
                href="https://policies.google.com/technologies/cookies?hl=en-GB#types-of-cookies" target="_blank"
                rel={"noreferrer"}>Google
                Analytics</a>,
                il quale fornisce gli strumenti per la misurazione dei profili di
                navigazione sul web. Tuttavia Google ha messo a punto un componente aggiuntivo per la disattivazione di
                Google Analytics per i browser più comuni: <a href="https://support.microsoft.com/en-us/kb/278835"
                                                              target="_blank" rel={"noreferrer"}>Microsoft Internet
                Explorer</a>, <a href="https://support.google.com/chrome/answer/95647?hl=en-GB" rel={"noreferrer"}
                                 target="_blank">Google
                Chrome</a>, <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences?redirectlocale=en-US&redirectslug=Enabling+and+disabling+cookies"
                target="_blank" rel={"noreferrer"}>Mozilla Firefox</a>, <a
                href="https://www.apple.com/support/?path=Safari/5.0/en/9277.html" target="_blank" rel={"noreferrer"}>Apple
                Safari</a> e Opera; tale componente facilita la disattivazione dell'uso dei relativi cookie. Per saperne
                di più potete visitare la seguente pagina web: https://tools.google.com/dlpage/gaoptout?hl=it.<br/>
                Cookie di terze parti per selezione,
                invio e reporting di annunci pubblicitari: tali Cookie ed altre tecnologie di tracciamento vengono
                installati da terze parti sul Sito al fine di raccogliere ed elaborare informazioni, nonchè combinare le
                stesse con dati raccolti in precedenza, per selezionare e mostrare all’Utente annunci pubblicitari
                personalizzati e per misurare la consegna e l'efficacia di tali annunci. Si prega di notare che ciò
                include l'utilizzo di informazioni raccolte in precedenza sugli interessi dell’Utente al fine di
                selezionare annunci personalizzati e trattare i dati relativi agli annunci mostrati, ossia quanto spesso
                sono stati mostrati tali annunci, quando e dove sono stati mostrati, o se l’Utente ha intrapreso
                qualsiasi azione relativa all'annuncio mostrato, inclusi, ad esempio, fare clic su un annuncio o su un
                determinato prodotto e/o contenuto. Tali Cookie sono cookie di profilazione, ossia cookie che raccolgono
                ed elaborano informazioni correlate agli interessi dell’Utente al fine di mostrare successivamente a
                quest’ultimo contenuti e/o annunci pubblicitari personalizzati in altri contesti, come su altri siti web
                o applicazioni mobile. Si prega di notare che tali terze parti non utilizzano informazioni di
                identificazione personale (quali, ad esempio, nome, indirizzo, indirizzo email o numero di telefono
                dell’Utente). Si noti inoltre che tali Cookie sono “cookie di terze parti”, i quali - per via del loro
                funzionamento - non sono nè gestiti nè accessibili dal Titolare del trattamento; pertanto quest’ultimo
                non è responsabile per la loro gestione e la loro finalità. Tuttavia, per maggiori informazioni sulle
                suddette terze parti, sulle finalità del loro trattamento e sul meccanismo di opt-out, si veda il
                seguente Articolo 7.<br/>
                Cookie di social network: tali Cookie
                permettono all’Utente di condividere il contenuto del Sito e – ove possibile – i c.d. “Mi piace” sui
                social network quali Facebook, Instagram, etc. Permettono inoltre all’Utente di interagire con i
                contenuti di ciascun social network. Si prega di notare che il funzionamento di tali Cookie e le
                informazioni raccolte dagli stessi sono disciplinati dall’informativa sulla privacy di ciascun social
                network, la quale è reperibile nell’elenco sottostante all’Articolo 7 della presente Informativa sui
                Cookie.<br/>
                Trasferimento di dati personali verso
                paesi terzi: l’Utente acconsente esplicitamente al trasferimento dei dati personali verso paesi terzi
                (USA) che non garantiscono un livello adeguato di protezione dei diritti e delle libertà delle persone
                fisiche con riguardo al trattamento dei dati personali, compresi i paesi per i quali non sono state
                adottate decisioni di adeguatezza e dove determinati programmi nazionali (al di fuori dell’Unione
                europea) possono garantire alle autorità pubbliche di accedere ai dati personali dell’Utente. In
                particolare, in seguito alla decisione della Corte di giustizia dell’Unione europea nella Causa C-311/18
                del 16 Luglio 2020 (Data Protection Commissioner / Facebook Ireland Ltd e Maximillian Schrems), la Corte
                di giustizia dell’Unione europea ha invalidato il c.d. EU-US Privacy Shield Framework. Pertanto, ad
                oggi, al fine di trasferire dati personali dallo Spazio Economico Europeo agli Stati Uniti sulla base
                delle deroghe previste dall’articolo 49, paragrafo 1, lettera a) del Regolamento UE 679/2016, è
                richiesto il consenso esplicito dell’Utente a tale trasferimento. A tal fine, è possibile ottenere qui
                di seguito ulteriori informazioni sul trasferimento dei dati verso gli Stati Uniti:<br/>
                per quanto riguarda lo scopo del
                trasferimento, si prega di notare che le terze parti aventi sede negli Stati Uniti possono raccogliere
                dati personali tramite cookie e/o altre tecnologie di tracciamento ogniqualvolta l’Utente visiti il Sito
                del Titolare del trattamento, al fine di misurare profili di navigazione sul web o per mostrare
                all’Utente pubblicità personalizzata;<br/>
                per quanto concerne la tipologia di dati
                trasferiti, si noti che trattasi di informazioni relative alle visite al Sito nonchè all’utilizzo del
                Sito e/o dei servizi ivi offerti, quali ad esempio il dispositivo e il browser utilizzati dall’Utente,
                l’indirizzo IP o i nomi dominio dei computer collegati al Sito, gli indirizzi in notazione URI delle
                richieste effettuate, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al
                server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della
                risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e
                all’ambiente informatico utilizzato dall’Utente, la data e l’orario della visita, la durata della
                visita, il sito di rinvio e il percorso di navigazione sul Sito relativo alla visita e alle interazioni
                sul Sito stesso, inclusi – laddove possibile – i servizi a cui l’Utente si mostri interessato;<br/>
                riguardo all’identità o categorie dei
                destinatari, si prega di notare che i dati personali possono essere trasferiti a società ad tech e/o ad
                altri soggetti che installano i loro cookie o altre tecnologie di tracciamento sul Sito, aventi sede
                negli Stati Uniti e che forniscono strumenti per la misurazione dei profili di navigazione sul web od
                opzioni di targeting pubblicitario. Un elenco di tali soggetti è presente nel seguente Articolo 7.<br/>
                per quanto concerne il rischio del
                trasferimento, si noti che la citata Corte di giustizia dell’Unione Europea ha evidenziato che - con
                riferimento al trasferimento di dati personali negli Stati Uniti - i requisiti del diritto interno
                statunitense, ed in particolare di alcuni programmi che consentono alle autorità pubbliche statunitensi
                di accedere ai dati personali trasferiti dall’Unione Europea agli Stati Uniti per finalità di sicurezza
                nazionale, comportano forti limitazioni alla protezione dei dati personali degli interessati. Ciò in
                quanto tali limitazioni non sono circoscritte in modo tale da soddisfare requisiti equivalenti a quelli
                previsti dal diritto europeo ed inoltre la legislazione statunitense non garantisce agli interessati
                diritti esercitabili dinanzi agli organi giurisdizionali contro le autorità statunitensi.<br/>
                infine, si prega di notare che la base
                giuridica per il trasferimento negli Stati Uniti dei suddetti dati personali è il consenso esplicito
                dell’Utente, laddove conferito da quest’ultimo.<br/>
            </p>
            <b>7. Elenco dei Cookie utilizzati nel Sito</b><br/>
            Qui di seguito sono elencati i Cookie utilizzati nel Sito:<br/>
            Google Analytics<br/>
            Google AdSense<br/>
            Le informazioni contenute nell’elenco sopracitato sono state fornite da terze parti che le hanno create e
            condivise con il Titolare del trattamento. Tali terze parti hanno le proprie informative sulla privacy e/o
            informative sui cookie nelle quali spiegano i trattamenti dei dati personali da loro effettuati, nonché le
            modalità di disattivazione applicabili ai cookie da loro installati. Il Titolare del trattamento non è
            responsabile dei contenuti nè dell’esattezza delle informative sulla privacy e/o delle informative sui
            cookie di terzi mostrate all’interno della presente Informativa sui Cookie.<br/><br/>
            <b>8. Perché vengono raccolti questi dati?</b><br/>
            Perché?<br/>
            A. Per creare e mantenere la relazione contrattuale stabilita per la fornitura del servizio richiesto in
            ogni sua fase ed in relazione ad ogni possibile integrazione e/o modifica.<br/>
            Su quali basi legali?<br/>
            Per fornire all’Utente il servizio richiesto<br/><br/>
            Perché?<br/>
            B. Per soddisfare i requisiti legali e regolamentari nonchè per rispondere a richieste avanzate da autorità
            governative o da soggetti incaricati dell’applicazione della legge che stanno conducendo un’indagine.<br/>
            Su quali basi legali?<br/>
            Per adempiere agli obblighi previsti dalla legge<br/><br/>
            Perché?<br/>
            C. Per svolgere analisi statistiche anonime e aggregative, al fine di poter verificare come gli utenti
            utilizzano il Sito e i servizi ivi forniti nonchè per analizzare l’andamento dell’attività del Titolare del
            trattamento.<br/>
            Su quali basi legali?<br/>
            Laddove si tratti di cookie tecnici in base alla deroga derivante dalla loro qualifica di “cookie
            strettamente necessari” (ad es. in quanto essenziali per il miglioramento delle funzioni e della gestione
            del Sito) o laddove l’Utente dia il proprio consenso (attraverso l’apposito Cookie Banner o tramite le
            impostazioni del proprio browser)<br/><br/>
            Perché?<br/>
            D. Per mostrare all’Utente inserzioni pubblicitarie personalizzate sulla base delle informazioni raccolte
            attraverso Cookie o altre tecnologie di tracciamento relativamente all’uso che l’Utente stesso fa del Sito,
            dei servizi ivi offerti e/o di altri siti web.<br/>
            Su quali basi legali?<br/>
            Laddove l’Utente dia il proprio consenso (attraverso l’apposito Cookie Banner o tramite le impostazioni del
            proprio browser)<br/><br/>

            Perché?<br/>
            E. Per consentire ad inserzionisti e/o soggetti terzi di personalizzare gli annunci pubblicitari e le
            notifiche di marketing online da mostrare sul Sito e/o su siti web di terzi, sulla base delle informazioni
            raccolte dagli stessi autonomamente sul Sito relativamente all’utilizzo che l’Utente fa del Sito
            medesimo.<br/>
            Su quali basi legali?<br/>
            Laddove l’Utente dia il proprio consenso (attraverso l’apposito Cookie Banner o tramite le impostazioni del
            proprio browser)<br/><br/>
            Perché?<br/>
            F. Per trasferire i dati personali dell’Utente verso paesi che non garantiscono un livello adeguato di
            protezione dei diritti e delle libertà delle persone fisiche con riguardo al trattamento dei dati personali
            (Stati Uniti), compresi i paesi per i quali non sono state adottate decisioni di adeguatezza e dove
            determinati programmi nazionali (al di fuori dell’Unione europea) possono garantire alle autorità pubbliche
            di accedere ai dati personali dell’Utente. <br/>
            Su quali basi legali?<br/>
            Laddove l’Utente dia il proprio consenso (attraverso l’apposito Cookie Banner o tramite le impostazioni del
            proprio browser)<br/><br/>
            <b>9. Per quanto tempo vengono conservati i dati?</b><br/>
            I dati personali vengono conservati per il tempo consentito dalla normativa applicabile, oppure per il tempo
            necessario a conseguire gli scopi e a compiere le attività descritte nella presente Informativa sui Cookie,
            nonchè per fini e tempistiche diversamente comunicate all’Utente (ove necessario). Qui di seguito sono
            disponibili maggiori informazioni relativamente al periodo di conservazione dei dati personali da parte del
            Titolare del trattamento:<br/>
            Dati raccolti mediante tag<br/><br/>
            Cookie tecnici<br/>
            Termine di conservazione<br/>
            Max. 3 anni<br/>
            Data di inizio<br/>
            A partire dalla data di navigazione sul Sito<br/>
            Dati raccolti mediante tag<br/><br/>
            Cookie non tecnici<br/>
            Termine di conservazione<br/>
            Max. 1 anno<br/>
            Data di inizio<br/>
            A partire dalla data di navigazione sul Sito<br/><br/>
            <b>10. Gestione dei Cookie</b><br/>
            10.1 Come si disabilitano/abilitano i Cookie?<br/>
            Ad esempio modificando le impostazioni privacy del browser l’Utente può scegliere di disabilitare e/o
            eliminare i Cookie oppure di ricevere una notifica ogniqualvolta sia previsto l’accesso a dati personali
            tramite cookie. Occorre tenere presente che ciascun browser ha una procedura diversa per gestire e
            configurare le impostazioni privacy dei Cookie. Qui di seguito sono disponibili maggiori informazioni
            relativamente alle modalità di gestione dei Cookie previste dai principali browser:<br/><br/>
            MICROSOFT WINDOWS EXPLORER<br/>
            GOOGLE CHROME<br/>
            MOZILLA FIREFOX<br/>
            APPLE SAFARI<br/><br/>
            Qualora si utilizzi un altro browser, si prega di consultare la relativa sezione “Impostazioni” per maggiori
            informazioni.<br/>
            Per la gestione e/o disattivazione dei Cookie su tablet o smartphone, si prega di consultare – laddove
            necessario – la relativa documentazione o le linee guida disponibili online per ciascun dispositivo.<br/>
            Tuttavia si prega di notare che se il Dispositivo dell’Utente non ha i Cookie attivati, l’esperienza sul
            Sito potrebbe essere limitata, impedendo dunque all’Utente una corretta navigazione nonchè l’utilizzo dei
            servizi offerti sul Sito dal Titolare del trattamento.<br/><br/>
            10.2 Come si abilitano/disabilitano i Cookie di terze parti?<br/>
            I Cookie di terze parti vengono installati dai partner del Titolare del trattamento o da terzi quando si
            visita il Sito. Per maggiori informazioni sulla gestione di tali Cookie si prega pertanto di consultare i
            siti web delle suddette terze parti. In alternativa, l’Utente può anche visitare il sito web
            http://www.youronlinechoices.com/it/, nel quale potrà trovare informazioni utili sull’utilizzo dei cookie
            che viene effettuato sul browser Web utilizzato dall’Utente, nonché sulle possibili misure da intraprendere
            per rimuovere le preferenze cookie che lo stesso ha impostato utilizzando determinati siti o per gestire la
            sua privacy online.<br/><br/>
            <b>11. Dati di contatto del titolare del trattamento e i diritti dell’Utente alla protezione dei suoi dati
                personali</b><br/>
            I dati di contatto del Titolare del trattamento dei dati di cui sopra sono:<br/>
            Alessandro Sangalli, C.F. SNGLSN80S30F704M – email: alessandros@kibukibu.com ed Alberto Noseda, C.F.
            NSDLRT75P28C933G – email: alberton@kibukibu.com.<br/>
            Per maggiori informazioni sul trattamento dei dati personali da parte del Titolare del trattamento nonchè
            sui diritti riconosciuti all’Utente dal Regolamento UE 679/2016, si prega di consultare l’Informativa sulla
            Privacy disponibile al seguente link: <Link to="/legal/privacy-policy">Informativa sulla Privacy</Link>
        </div>
    </LegalContainer>
</>
