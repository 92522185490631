import React from "react";
import {useRecoilValue} from "recoil";
import {dataAtom} from "../../atoms/dataAtom";

type Props = {
    keyRef: string
}

export const Label: React.FC<Props> = ({keyRef}) => {
    const labels = useRecoilValue(dataAtom)?.labels
    return labels ? <span dangerouslySetInnerHTML={{ __html: labels[keyRef] }}></span> : null;
}