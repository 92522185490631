import React from 'react';
import { Toy } from '../../atoms/dataAtom'
import './listingItem.scss'
import {convertHtmlEntities, formatPrice, goToToy} from "../../util/global";
import {Label} from "../Label/Label";

const ListingItem:React.FC<{element: Toy}> = ({element}) => {
    const itemNameLengthLimit = 60
    const onRowClick: (e: React.MouseEvent<HTMLDivElement>) => void = (e) => goToToy(element.internalLink, true)
    const originalItemName = convertHtmlEntities(element.name)
    const itemName = originalItemName.length > itemNameLengthLimit ? originalItemName.substring(0, itemNameLengthLimit - 3) + "..." : originalItemName
    return (
        <div className="listingItemContainer">
            <div className="listingItemImage" style={{backgroundImage: `url(${element.images[0]})`}} onClick={onRowClick}></div>
            <div className="listingItemDescription" onClick={onRowClick}>
                <div className="listingItemTitle">
                    {itemName}
                </div>
                <div className="listingItemStore">
                    <span className="listingItemStoreLabel">Negozio: </span>
                    <span className="listingItemStoreName">{element.merchant}</span>
                </div>
                <div className="listingItemAvailabilityPrice">
                    <div className="listingItemAvailabilityLabel">Disponibilit&agrave;: </div>
                    <div className="listingItemAvailabilityButton">
                        <button className={`available ${element.availability.toLowerCase()}`}><Label keyRef={`toy.status.${element.availability}`} /></button>
                    </div>
                    <div className="listingItemPrice">{formatPrice(element.price)}</div>
                </div>
            </div>
            <div className="listingItemActions">
                <a className="listingItemActionsButton" href={element.internalLink}>Dettagli &gt;</a>
                <a className="listingItemActionsButton listingItemActionsStore" href={`/redirect/${element.id}`} target="_blank" rel="noopener noreferrer">Vai al Negozio</a>
            </div>
        </div>
    );
}

export { ListingItem }