import Slider from "react-slick"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./gallery.scss"
import {goToLink, goToToy} from "../../util/global"
import React from "react";

export type GalleryToy = {
    imageUrl: string,
    name: string,
    extract?: string,
    id?: string,
    internalLink?: string,
    link?: string
}

const SlideText: React.FC<{
    title: string,
    extract?: string,
    titledLayout: boolean
}> = ({extract, titledLayout, title}) => {
    return <div className="slideText">
        {titledLayout && extract ?
            <><h5 dangerouslySetInnerHTML={{__html: title}}></h5><div>{extract}</div></> :
            title.length > 100 ? `${title.substring(0, 100)}...` : title}
    </div>
}

export const Gallery: React.FC<{
    title: string,
    elements?: GalleryToy[],
    titledLayout?: boolean
}> = ({title, elements, titledLayout}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: Math.min(elements?.length ?? 4, 4),
        slidesToScroll: Math.min(elements?.length ?? 4, 4),
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: Math.min(elements?.length ?? 3, 3),
                    slidesToScroll: Math.min(elements?.length ?? 3, 3)
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: Math.min(elements?.length ?? 2, 2),
                    slidesToScroll: Math.min(elements?.length ?? 2, 2)
                }
            }
        ]
    };

    const onSlideClick = (e: GalleryToy) => (evt: React.MouseEvent) => {
        evt.preventDefault();
        e.internalLink ? goToToy(e.internalLink, true) : goToLink(e.link!!)
    }

    return elements?.length ? (
        <div className={`galleryContainer${titledLayout ? ' titled' : ''}`}>
            <div className="galleryTitle">{title}</div>
            <Slider {...settings}>
                {elements.map(e =>
                    <a className="slide" key={e.name} onClick={onSlideClick(e)} href={e.internalLink || e.link!!}>
                        <div className="slideContainer">
                            <div className="slideImg" style={{backgroundImage: "url(" + e.imageUrl + ")"}}></div>
                            <SlideText title={e.name} extract={e.extract} titledLayout={titledLayout ?? false}/>
                        </div>
                    </a>
                )}
            </Slider>
        </div>
    ) : null;
}