import { selector } from "recoil";
import {dataAtom, getCurrentSearch} from "./dataAtom";
import { defaultSearch } from '../util/global'

export type Search = {
    filter: string,
    fromPrice: string | undefined,
    toPrice: string | undefined,
    availability?: string[],
    merchant: string[],
    page: number,
    orderBy: string,
    orderByDirection: string
}

export const getStartPage = selector({
    key: 'getStartPage',
    get: ({ get }) => Math.max(get(getCurrentSearch).page - 3, 1)
});

export const getEndPage = selector({
    key: 'getEndPage',
    get: ({ get }) => Math.min(get(getCurrentSearch).page + 3, get(dataAtom)?.totalPages ?? 0)
});

export const areThereFiltersApplied = selector({
    key: 'areThereFiltersApplied',
    get: ({get}) => {
        const search = get(getCurrentSearch)
        return search.toPrice !== defaultSearch.toPrice ||
            search.fromPrice !== defaultSearch.fromPrice ||
            search.merchant !== defaultSearch.merchant ||
            search.availability !== defaultSearch.availability
    }
})

export const areTherePriceFiltersApplied = selector({
    key: 'areTherePriceFiltersApplied',
    get: ({get}) => {
        const search = get(getCurrentSearch)
        return search.toPrice !== defaultSearch.toPrice || search.fromPrice !== defaultSearch.fromPrice
    }
})