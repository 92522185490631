import Logo from '../../assets/brand.png'
import './footerLegal.scss'
import {Link} from "react-router-dom";

const FooterLegal = () => 
    <div className='footerLegalContainer'>
        <a className="footerLegalBox footerLegalBoxLogo" href="/">
            <img src={Logo} alt="kibukibu" /> kibukibu.com
        </a>
        <div className="footerLegalBox">
            <div className="footerLegalBoxLink">
                <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="footerLegalBoxLink">
                <Link to="/legal/cookie-policy">Cookies Policy</Link>
            </div>
            <div className="footerLegalBoxLink">
                <Link to="/legal/terms-conditions">Termini e Condizioni</Link>
            </div>
        </div>
        <div className="footerLegalBox footerLegaCopyright">
            Copyright &copy; 2024 kibukibu.com<br/>all rights reserved
        </div>
    </div>

export { FooterLegal }