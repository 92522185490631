import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import React from "react";

const CookieConsentElement = () => {
    React.useEffect(() => {
        if ( getCookieConsentValue("cookieConsentV2") === "true") {
            onAccept(false)
        }
    }, [])
    const onAccept = (acceptedByScrolling: boolean) => {
        console.log("accepted")
        function gtag() {
            // @ts-ignore
            window.dataLayer.push(arguments);
        }

        // @ts-ignore
        gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted'
        });

        // Load gtag.js script.
        const gtagScript = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-TZEVL1W03Z';

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript?.parentNode?.insertBefore(gtagScript,firstScript);
    }
    return <CookieConsent
        location="bottom"
        cookieName="cookieConsentV2"
        style={{background: "#3BA2FF"}}
        buttonStyle={{background: "#F257A2", fontSize: "13px", color: "#fff", height: "40px"}}
        expires={150}
        buttonText={"Ho capito"}
        onAccept={onAccept}
        acceptOnScroll={true}
        acceptOnScrollPercentage={15}
    >
        Questo sito web utilizza i cookie per migliorare l'esperienza utente. Continuando a navigare accetti la nostra privacy policy.<br/>
        <span style={{fontSize: "12px"}}>
            Clicca <a href="/legal/privacy-policy" style={{color: "#fff"}}>qui</a> per saperne di pi&ugrave;
        </span>
    </CookieConsent>;
}

export { CookieConsentElement }