import React, {ChangeEvent, KeyboardEvent, useState} from 'react';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank,
    MdRadioButtonChecked,
    MdRadioButtonUnchecked
} from 'react-icons/md'
import {useRecoilState, useRecoilValue} from 'recoil';
import {dataAtom, getCurrentSearch} from '../../atoms/dataAtom';
import {areThereFiltersApplied, areTherePriceFiltersApplied} from '../../atoms/searchAtom';
import {defaultSearch, gotoFirstPageSearch} from '../../util/global';
import './filters.scss'
import {VscFilter, VscFilterFilled} from "react-icons/vsc";
import {GoChevronDown, GoChevronUp} from "react-icons/go"
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const SelectableFilter: React.FC<{
    group: string,
    label?: string,
    value: string,
    defaultChecked?: boolean,
    onSelection: (e: any) => void,
    type?: string
}> = ({group, label, value, defaultChecked, onSelection, type = "radio"}) => {
    const IconChecked = type == "radio" ? MdRadioButtonChecked : MdOutlineCheckBox
    const IconUnchecked = type == "radio" ? MdRadioButtonUnchecked : MdOutlineCheckBoxOutlineBlank
    return <label className="filtersOptionContainer">
        <input type={type} name={group} checked={defaultChecked} value={value} onChange={onSelection}/>
        <IconUnchecked size={24} className='check-icon unchecked-icon' color='#666'/>
        <IconChecked size={24} className='check-icon checked-icon' color='#666'/>
        <div className={"filtersOptionContainerText"} title={label}>{label}</div>
    </label>;
}

const ControlledFilterSlider: React.FC<{
    min: number,
    max: number,
    currentFrom: number,
    currentTo: number,
    onSliderInput: (values: number[], ui: boolean) => void,
    onSliderSelection: () => void
}> = ({min, max, onSliderInput, currentTo, currentFrom, onSliderSelection}) =>
    <RangeSlider
        className="price-slider"
        min={min}
        max={max}
        value={[currentFrom, currentTo]}
        onInput={onSliderInput}
        onThumbDragEnd={onSliderSelection}
        onRangeDragEnd={onSliderSelection}
    />

const Filters = () => {
    const merchantsLimit = 10
    const [data,] = useRecoilState(dataAtom)
    const search = useRecoilValue(getCurrentSearch)
    const [currentPrices, setCurrentPrices] = useState({fromPrice: search.fromPrice, toPrice: search.toPrice})
    const [showMobileFilters, setShowMobileFilters] = useState(false)
    const availableMerchants = React.useMemo(() => data?.merchants?.filter(m => data?.filterResult?.merchants?.includes(m.id) ?? true), [data?.filterResult?.merchants, data?.merchants])
    const [selectedMerchants, setSelectedMerchants] = React.useState(search.merchant)
    const [selectedAvailabilities, setSelectedAvailabilities] = React.useState(search.availability)
    const [anySearchChanged, setAnySearchChanged] = useState(false)

    const firstMerchants = availableMerchants?.slice(0, merchantsLimit) ?? [];
    const otherMerchants = React.useMemo(() => availableMerchants?.slice(merchantsLimit, data?.merchants.length) ?? [], [availableMerchants, data?.merchants])
    const [showMoreMerchants, setShowMoreMerchants] = React.useState(otherMerchants.some(m => selectedMerchants.includes(m.id)))
    React.useEffect(() => setShowMoreMerchants(otherMerchants.some(m => selectedMerchants.includes(m.id))), [otherMerchants, selectedMerchants])
    React.useEffect(() => setSelectedMerchants(search.merchant), [search.merchant])
    React.useEffect(() => setSelectedAvailabilities(search.availability), [search.availability])

    const onOkClick = () => gotoFirstPageSearch({...search, ...currentPrices})

    const onSearchOkClick = () => gotoFirstPageSearch({...search, ...currentPrices, merchant: selectedMerchants, availability: selectedAvailabilities})
    const changePrice = (field: 'fromPrice' | 'toPrice', input: HTMLInputElement) =>
        setCurrentPrices({...currentPrices, [field]: input.value})
    const onFromPriceChange = (e: KeyboardEvent<HTMLInputElement>) => changePrice('fromPrice', e.currentTarget)
    const onFromPriceChangeV2 = (e: ChangeEvent<HTMLInputElement>) => changePrice('fromPrice', e.currentTarget)
    const onToPriceChange = (e: KeyboardEvent<HTMLInputElement>) => changePrice('toPrice', e.currentTarget)
    const onToPriceChangeV2 = (e: ChangeEvent<HTMLInputElement>) => changePrice('toPrice', e.currentTarget)
    const onRangePriceChange = (down: number, up: number) =>
        setCurrentPrices({...currentPrices, toPrice: up.toString(), fromPrice: down.toString()})


    const onCheckboxChange = (selectedOptions: string[] | undefined, setSelectedOptions: (x: any) => any, searchField: string) => (e: any, newOption: string) => {
        const currentOptions = selectedOptions ?? []
        if (newOption === "ALL") {
            gotoFirstPageSearch({...search, [searchField]: ["ALL"]})
        } else {
            let newSelectedOptions = []
            if (e.target.checked) {
                newSelectedOptions = [...new Set(currentOptions.concat(newOption))]
            } else {
                newSelectedOptions = currentOptions.filter(m => m !== newOption)
            }
            newSelectedOptions = newSelectedOptions.filter(m => m !== "ALL")
            if (newSelectedOptions.length === 0) newSelectedOptions = ["ALL"]
            setSelectedOptions(newSelectedOptions)
        }
        setAnySearchChanged(true)
    }

    const onMerchantChange =
        onCheckboxChange(selectedMerchants, setSelectedMerchants, "merchant")
    const onAvailabilityChange =
        onCheckboxChange(selectedAvailabilities, setSelectedAvailabilities, "availability")

    const onOrderByChange = (orderBySelection: string) => {
        const [orderBy, orderByDirection] = orderBySelection.split("_")
        gotoFirstPageSearch({...search, orderBy, orderByDirection})
    }

    const onResetFiltersClick = (e: React.MouseEvent) => {
        gotoFirstPageSearch({...defaultSearch, filter: search.filter})
        e.stopPropagation()
    }

    const onResetPriceFilterClick = () => {
        gotoFirstPageSearch({...search, toPrice: defaultSearch.toPrice, fromPrice: defaultSearch.fromPrice})
    }

    const sidebarBoxAdditionalClass = showMobileFilters ? " listingSidebarBoxOpen" : ""
    const onSliderInput: (values: number[], ui: boolean) => void =
        ([down, up], ui) => onRangePriceChange(down, up);
    const onSliderSelection = () => gotoFirstPageSearch({...search, ...currentPrices})
    React.useEffect(() => setCurrentPrices({fromPrice: search.fromPrice, toPrice: search.toPrice}), [search])
    const initialHasFilter = useRecoilValue(areThereFiltersApplied)
    const initialHasPriceFilter = useRecoilValue(areTherePriceFiltersApplied)
    const filterMinPrice = React.useMemo(() => Math.floor(+(search.fromPrice ?? data?.filterResult?.minPrice ?? 0)), [data?.filterResult, search.fromPrice])
    const filterMaxPrice = React.useMemo(() => Math.ceil(+(search.toPrice ?? data?.filterResult?.maxPrice ?? 9999)), [data?.filterResult, search.toPrice])

    return <>
        <div className={"mobileFiltersToggle"} onClick={() => setShowMobileFilters(!showMobileFilters)}>
            {initialHasFilter ? <>Filtri Attivi<VscFilterFilled/> <span className="clearFilters"
                                                                        onClick={onResetFiltersClick}>Pulisci filtri</span></> : <>Filtri <VscFilter/></>}
        </div>
        <div className={`listingSidebarBox${sidebarBoxAdditionalClass}`}>
            <div className="listingSidebarTitle">{data?.labels?.["search.sort.by.title"]}</div>
            <div className="filtersOptions">
                {["NAME_ASC", "NAME_DESC", "PRICE_ASC", "PRICE_DESC"].map(ob => <SelectableFilter
                    group='orderBy'
                    label={data?.labels?.[`search.sort.by.${ob}`] ?? ob}
                    defaultChecked={`${search.orderBy}_${search.orderByDirection}` === ob}
                    value={ob}
                    onSelection={() => onOrderByChange(ob)}
                    key={ob}
                />)}
            </div>
            <div className="listingSidebarTitle">
                {data?.labels?.["search.filter.price.title"]}
                {initialHasPriceFilter ?
                    <>
                        {" "}- <span className="priceFilterReset" onClick={onResetPriceFilterClick}>
                            {data?.labels?.["search.filter.price.reset"]}
                        </span>
                    </> : null
                }
            </div>
            <div className="filtersPrice">
                <div className="filtersPriceInputContainer">
                    <div className="filtersPriceInput">
                        <input
                            type="text"
                            name='from'
                            placeholder={filterMinPrice.toString()}
                            value={currentPrices.fromPrice}
                            onKeyUp={onFromPriceChange}
                            onChange={onFromPriceChangeV2}
                        />
                    </div>
                    <div className="filtersPriceCurrency">€</div>
                </div>
                <div className="filtersPriceInputSeparator">-</div>
                <div className="filtersPriceInputContainer">
                    <div className="filtersPriceInput">
                        <input
                            type="text"
                            name='to'
                            placeholder={filterMaxPrice.toString()}
                            value={currentPrices.toPrice ?? ''}
                            onKeyUp={onToPriceChange}
                            onChange={onToPriceChangeV2}
                        />
                    </div>
                    <div className="filtersPriceCurrency">€</div>
                </div>
            </div>
            <ControlledFilterSlider
                min={filterMinPrice}
                max={filterMaxPrice}
                currentFrom={+(currentPrices.fromPrice ?? filterMinPrice)}
                currentTo={+(currentPrices.toPrice ?? filterMaxPrice)}
                onSliderInput={onSliderInput}
                onSliderSelection={onSliderSelection}
            />
            <div className="filtersPriceInputOk" onClick={onOkClick}>Ok</div>
            <div className="listingSidebarTitle">{data?.labels?.["search.filter.availability.title"]}</div>
            <div className="filtersOptions">
                {["ALL", "IN_STOCK", "PRE_ORDER", "NOT_AVAILABLE"].map(av =>
                    <SelectableFilter
                        group='availability'
                        label={data?.labels?.[`toy.status.${av}`] ?? av}
                        defaultChecked={selectedAvailabilities?.includes(av) ?? av === "ALL"}
                        value={av}
                        onSelection={(e) => onAvailabilityChange(e, av)}
                        key={av}
                        type={'checkbox'}
                    />
                )}
            </div>
            <div className="listingSidebarTitle">{data?.labels?.["global.merchant"]}</div>
            <div className="filtersOptions">
                <SelectableFilter
                    type={'checkbox'}
                    group='store'
                    label={data?.labels?.["search.filter.merchant.all"]}
                    defaultChecked={selectedMerchants.includes("ALL")}
                    value={"ALL"}
                    onSelection={() => onMerchantChange(null, "ALL")}
                />
                {firstMerchants.map(m =>
                    <SelectableFilter
                        group='store'
                        defaultChecked={selectedMerchants.includes(m.id)}
                        label={m.name}
                        value={m.id}
                        key={m.id}
                        onSelection={(e) => onMerchantChange(e, m.id)}
                        type="checkbox"
                    />
                )}
                <div style={{display: showMoreMerchants ? "block" : "none"}}>
                    {otherMerchants.map(m =>
                        <SelectableFilter
                            group='store'
                            defaultChecked={selectedMerchants.includes(m.id)}
                            label={m.name}
                            value={m.id}
                            key={m.id}
                            onSelection={(e) => onMerchantChange(e, m.id)}
                            type="checkbox"
                        />
                    )}
                </div>
            </div>
            <div
                className="filtersShowMoreMerchants"
                onClick={() => setShowMoreMerchants(!showMoreMerchants)}
                style={{display: otherMerchants.length > 0 ? "flex" : "none"}}
            >
                {showMoreMerchants ?
                    data?.labels?.["search.filter.merchant.show.less"] :
                    `${data?.labels?.["search.filter.merchant.show.more"]} (${otherMerchants.length})`
                }
                {showMoreMerchants ? <GoChevronUp size={20}/> : <GoChevronDown size={20}/>}
            </div>
            <div className="filtersPriceInputOk" onClick={onSearchOkClick}
                 style={{display: anySearchChanged ? "block" : "none"}}>Ok
            </div>
            {initialHasFilter ?
                <div className="clearFilters"
                     onClick={onResetFiltersClick}>{data?.labels?.["search.filter.clear.all"]}</div> : null}
        </div>
    </>
}

export {Filters}