import React from "react";
import {useRecoilValue} from "recoil";
import {Line, LineChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {getToyPrices} from "../../atoms/toyPricesAtom";
import {Toy} from "../../atoms/dataAtom";
import './detailToyPrices.scss'
import {formatPrice} from "../../util/global";


const CustomizedLabel: React.FC<any> = ({x, y, stroke, value}) =>
    <>
        <text
            x={x}
            y={y}
            dy={-6}
            fill={stroke}
            fontSize={14}
            textAnchor="middle"
            style={{fill: "#3BA2FF", stroke: "white", strokeWidth: "0.2em"}}
        >{formatPrice(value)}</text>
        <text
            x={x}
            y={y}
            dy={-6}
            fill={stroke}
            fontSize={14}
            textAnchor="middle"
            style={{fill: "#3BA2FF"}}
        >{formatPrice(value)}</text>
    </>

export const DetailToyPrices: React.FC<{ toy?: Toy }> = ({toy}) => {
    const toyPrices = useRecoilValue(getToyPrices(toy?.id ?? ""))
    const allPrices = toyPrices?.map(t => t.price) ?? [0]
    const maxPrice = Math.max(...allPrices)
    const up = Math.round(maxPrice * 1.2)
    const down = Math.round(maxPrice * 0.8)

    return <div className="detailToyPrices">
        <div className="detailToyPricesTitle">Andamento del prezzo:</div>
        <ResponsiveContainer height={250}>
            <LineChart height={250} data={toyPrices} margin={{right: 20, left: 0}}>
                <Line
                    type="linear"
                    dataKey="price"
                    stroke="#F257A2"
                    label={<CustomizedLabel/>}
                    isAnimationActive={false}
                />
                <XAxis dataKey="timestamp" padding={{left: 40, right: 40}}/>
                <YAxis fontSize={12} color={"#CCC"} padding={{top: 20}} domain={[down, up]}/>
            </LineChart>
        </ResponsiveContainer>
    </div>
}