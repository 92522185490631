import Face1 from '../../assets/face1.png'
import Face2 from '../../assets/face2.png'
import Face3 from '../../assets/face3.png'

import "./footerClaim.scss"

export const FooterClaim = () => <div className="footerClaim">
<div className="footerClaimElement">
    <img src={Face1} /><br />
    <div className="footerClaimElementTitle">vogliamo migliorare!</div>
    <div className="footerClaimElementDescription">siamo affamati di feedback<br />condividi le tue idee</div>
</div>
<div className="footerClaimElement">
    <img src={Face2} /><br />
    <div className="footerClaimElementTitle">negozio mancante?</div>
    <div className="footerClaimElementDescription">segnalaci il tuo<br />negozio di fiducia</div>
</div>
<div className="footerClaimElement">
    <img src={Face3} /><br />
    <div className="footerClaimElementTitle">pi&ugrave; siamo meglio &eacute;!</div>
    <div className="footerClaimElementDescription">diffondi il verbo!<br />dillo ai tuoi amici!</div>
</div>
</div>