import React, {useState} from "react";
import "./contactUs.scss"
import {ContactData} from "../../pages/Contact";

type FormData = {
    type: string,
    name: string,
    shop: string,
    shopUrl: string,
    email: string,
    message: string
}

export const ContactUs: React.FC<{
    onContact: (data: ContactData) => Promise<boolean>,
    validate: (data: ContactData) => boolean
}> = ({onContact, validate}) => {
    const [data, setData] = useState<FormData>({
        type: "customer",
        name: "",
        shop: "",
        shopUrl: "",
        email: "",
        message: ""
    })
    const [showError, setShowError] = useState(false)
    const [showValidation, setShowValidation] = useState(false)

    const isUser = data.type === "customer"

    const onFormUpdate: (field: string, value: string) => void = (field, value) => setData({...data, [field]: value})
    const onSubmit = () => {
        setShowValidation(false)
        if (!validate(data)) setShowValidation(true)
        else onContact(data).then((r) => setShowError(!r))
    }

    return <div className="contactContainer">
        <div className="contactTitle">Contattaci</div>
        <div className="contactForm">
            <div className="contactFormField">
                <div className="contactFormLabel">Chi sei?</div>
                <div className="contactFormInput">
                    <select name="type" onChange={e => onFormUpdate("type", e.target.value)} value={data.type}>
                        <option value="customer">Utente</option>
                        <option value="merchant">Negozio</option>
                    </select>
                </div>
            </div>
            <div className="contactFormField">
                <div className="contactFormLabel">Nome *</div>
                <div className="contactFormInput">
                    <input name="name" placeholder="il tuo nome" onChange={e => onFormUpdate("name", e.target.value)}/>
                </div>
            </div>
            <div className="contactFormField" style={{display: !isUser ? "flex" : "none"}}>
                <div className="contactFormLabel">Negozio *</div>
                <div className="contactFormInput">
                    <input name="shop" placeholder="il nome del tuo negozio"
                           onChange={e => onFormUpdate("shop", e.target.value)}/>
                </div>
            </div>
            <div className="contactFormField" style={{display: !isUser ? "flex" : "none"}}>
                <div className="contactFormLabel">Url</div>
                <div className="contactFormInput">
                    <input name="shopUrl" placeholder="URL del negozio"
                           onChange={e => onFormUpdate("shopUrl", e.target.value)}/>
                </div>
            </div>
            <div className="contactFormField">
                <div className="contactFormLabel">Email *</div>
                <div className="contactFormInput">
                    <input name="email" placeholder="la tua email"
                           onChange={e => onFormUpdate("email", e.target.value)}/>
                </div>
            </div>
            <div className="contactFormField">
                <div className="contactFormLabel">Messaggio *</div>
                <div className="contactFormInput">
                    <textarea
                        name="message"
                        placeholder="Come possiamo aiutarti?"
                        onChange={e => onFormUpdate("message", e.target.value)}
                    ></textarea>
                </div>
            </div>
            <div className="contactFormField">
                <div className="contactFormLabel contactFormLabel-privacy">&nbsp;</div>
                <div className="contactFormInput contactFormInput-privacy">Procedendo, acconsenti all'invio e al
                    trattamento del messaggio in
                    conformit&agrave; con la <a href="/legal/privacy-policy">politica sulla privacy</a> di questo sito
                    web.
                </div>
            </div>
            <div className="contactFormField">
                <div className="contactFormLabel"></div>
                <div className="contactFormInput">
                    <div className="contactFormInputSend" onClick={onSubmit}>Invia</div>
                </div>
            </div>

            {showError ? <div className="contactFormField">
                <div className="contactFormLabel"></div>
                <div className="contactFormInput contactFormError">
                    Errore durante l'invio della richiesta, riprova pi&ugrave; tardi
                </div>
            </div> : null}
            {showValidation ? <div className="contactFormField">
                <div className="contactFormLabel"></div>
                <div className="contactFormInput contactFormError">
                    Compila correttamente tutti i campi contrassegnati con *
                </div>
            </div> : null}

        </div>
    </div>
}