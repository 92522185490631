import React from "react";
import "./legalContainer.scss"

type Props = {
    children: JSX.Element,
};

export const LegalContainer: React.FC<Props> = ({children}) => {
    return <div className={"legalContainer"}>
        {children}
    </div>
}
