import { useRecoilValue } from "recoil"
import { dataAtom } from "../atoms/dataAtom"
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb"
import { DetailToy } from "../components/DetailToy/DetailToy"
import { DetailToyInfo } from "../components/DetailToyInfo/DetailToyInfo"
import { Gallery } from "../components/Gallery/Gallery"

const Detail = () =>
    {
        const data = useRecoilValue(dataAtom)
        const toy = data?.toy
        const flags = data?.flags ?? {}

        return toy ? <>
            <Breadcrumb crumbs={["Home", toy?.name]} />
            <DetailToy toy={toy} />
            <DetailToyInfo toy={toy} flags={flags} />
            <Gallery title="Toy dallo stesso brand" elements={data.brandRelatedToys?.map(e => ({...e, imageUrl: e.mainImage}))}  />
            <Gallery title="Altri collezionisti hanno cercato anche queste figures" />
        </> : null
    }

export { Detail }