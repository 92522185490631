import {LegalContainer} from "../components/LegalContainer/LegalContainer";
import {Link} from "react-router-dom";


export const Privacy = () => <>
    <LegalContainer>
        <div className="container">
            <h1>INFORMATIVA SULLA PRIVACY</h1>
            <p>
                La presente Informativa sulla Privacy è resa, nel rispetto degli artt. 13 e 14 del Regolamento UE
                679/2016 (di seguito “Regolamento”), agli utilizzatori (di seguito: “Utente” e/o “Utenti”) del sito
                internet www.kibukibu.com (di seguito: “Sito”) di proprietà di Alessandro Sangalli, C.F.
                SNGLSN80S30F704M ed Alberto Noseda, C.F. NSDLRT75P28C933G (i quali sono i titolari del trattamento dei
                dati personali, di seguito: “Titolare del trattamento”), ed ha lo scopo di descrivere le modalità di
                gestione del Sito con riferimento al trattamento dei dati personali, nonché di consentire agli Utenti
                del Sito di conoscere le finalità e le modalità di trattamento dei dati personali da parte del Titolare
                del trattamento in caso di loro conferimento a quest’ultimo. Laddove invece, durante la navigazione sul
                Sito, l’Utente acceda mediante link a pagine o a siti gestiti da terze parti, per il trattamento dei
                dati personali dovrà farsi riferimento alle informative sulla privacy ivi pubblicate.<br/>
                In particolare, questa Informativa sulla Privacy descrive come il Titolare del trattamento raccoglie,
                utilizza, tratta e comunica i dati personali dell’Utente in caso di accesso al Sito ed utilizzo dello
                stesso e dei servizi ivi previsti, nello specifico:<br/>
                <p>
                    1. Chi è il Titolare del trattamento dei dati personali?<br/>
                    2. Principi applicabili al Trattamento dei dati personali<br/>
                    3. Quali categorie di dati raccoglie ed utilizza il Titolare del trattamento?<br/>
                    4. Perché vengono raccolti i dati personali?<br/>
                    5. Chi vede, riceve e utilizza i dati e dove è possibile fare tutto ciò?<br/>
                    6. Modalità di trattamento e conservazione dei dati personali<br/>
                    7. Quali sono i diritti alla protezione dei dati e come possono essere esercitati?<br/>
                    8. Dati di contatto del Titolare del trattamento<br/>
                    9. Informazioni relative ai cookie<br/>
                    10. Aggiornamento e versioni precedenti della presente Informativa sulla Privacy<br/>
                </p>
                Il presente documento informa inoltre l’Utente su come esercitare i propri diritti (incluso il diritto
                di opposizione relativo alla gestione dei dati svolta dal Titolare del trattamento). Maggiori
                informazioni in merito ai diritti e alle modalità di esercizio degli stessi sono riportate nei paragrafi
                successivi della presente Informativa sulla Privacy.<br/>
                Come specificato nelle Condizioni generali per l’utilizzo del Sito, i servizi offerti dal Titolare del
                trattamento sono rivolti a persone di età superiore ai 18 anni. Qualora il Titolare del trattamento
                dovesse venire a conoscenza del trattamento di dati di minori di 18 anni di età senza un valido consenso
                dei genitori o di un tutore legale, si riserva il diritto di interrompere unilateralmente la fruizione
                del servizio offerto nonché di cancellare i dati acquisiti.<br/>
                I termini che non vengono definiti nella presente Informativa sulla privacy (quali ad esempio
                “Servizio”, “Prodotto” o “Venditore”) hanno lo stesso significato descritto nelle Condizioni generali
                per l’utilizzo del Sito disponibili al seguente link.<br/><br/>
                <b>1. Chi è il Titolare del trattamento dei dati personali?</b><br/>
                Laddove all’interno della presente Informativa sulla Privacy siano presenti i termini “Titolare del
                trattamento” o “suo/suoi”, essi si intendono fare riferimento a:<br/>
                Alessandro Sangalli, C.F. SNGLSN80S30F704M ed Alberto Noseda, C.F. NSDLRT75P28C933G, i quali sono
                titolari del trattamento dei dati personali degli Utenti ai sensi della presente Informativa sulla
                Privacy.<br/><br/>

                <b>2. Principi applicabili al trattamento dei dati personali</b><br/>
                Il Titolare del trattamento, ai sensi e per gli effetti del Regolamento, rende noto che la citata
                normativa prevede la tutela delle persone fisiche rispetto al trattamento dei dati personali, e che tale
                trattamento sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della
                riservatezza e dei diritti fondamentali.<br/><br/>
                <b>3. Quali categorie di dati raccoglie ed utilizza il Titolare del trattamento?</b><br/>
                Qualora l’Utente visiti il Sito e utilizzi il servizio di ricerca e/o di comparazione, o si registri ad
                uno dei servizi offerti dal Sito stesso, il Titolare del trattamento raccoglie le seguenti categorie di
                dati personali:<br/>
                <p>
                    <b>3.1. Dati personali forniti dall’Utente</b><br/>
                    I dati personali condivisi con il Titolare del trattamento, inclusi quelli inviati tramite la
                    sezione del Sito “Contattaci”, nonché quelli forniti al Titolare del trattamento durante l’utilizzo
                    dei servizi offerti sul Sito.<br/>
                    In particolare:<br/>
                    &nbsp;&nbsp;&nbsp;<i className="fa fa-circle"></i>&nbsp;quando si verifica
                    un contatto tra il Titolare del trattamento e l’Utente attraverso la Sezione del Sito “Contattaci”
                    oppure tramite e-mail e/o mediante i social network, il Titolare del trattamento può raccogliere i
                    seguenti dati: nome e cognome dell’Utente, nome utente, nome del negozio gestito dall’Utente e
                    relativo URL del sito web, numero di telefono (laddove necessario) ed indirizzo e-mail
                    dell’Utente.<br/>
                    I dati personali sopracitati, quando richiesti, sono necessari per un’adeguata esecuzione del
                    contratto tra il Titolare del trattamento e l’Utente, nonché per consentire al Titolare del
                    trattamento di adempiere ai suoi obblighi legali, fatto salvo il caso in cui il trattamento dei dati
                    non abbia come base legale il consenso dell’interessato e/o il legittimo interesse del Titolare del
                    trattamento. Senza tali informazioni infatti quest’ultimo potrebbe non essere in grado di fornire
                    tutti i servizi richiesti.<br/>
                    Inoltre, è importante che tutti i dati personali forniti dall’Utente siano esatti. Ciò significa, a
                    titolo puramente esemplificativo, l’assicurazione da parte dell’Utente che i dati di contatto in
                    possesso del Titolare del trattamento (incluso l’indirizzo e-mail) siano sempre corretti.<br/>
                    <b>3.2. Dati personali raccolti automaticamente dal Sito, dalle comunicazioni inviate dal Titolare
                        del trattamento</b><br/>
                    Il Titolare del trattamento raccoglie informazioni relative alle visite al Sito e all’utilizzo del
                    Sito, quali ad esempio il dispositivo e il browser utilizzati, l’indirizzo IP o i nomi dominio dei
                    computer collegati al Sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle
                    richieste effettuate, l’orario della richiesta, la data e l’orario della visita, la durata della
                    visita, il sito di rinvio e il percorso di navigazione sul Sito relativo alla visita e alle
                    interazioni sul Sito stesso, il metodo utilizzato nel sottoporre la richiesta al server, la
                    dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data
                    dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente
                    informatico dell’Utente.<br/>
                    Per ulteriori informazioni sugli scopi per i quali il Titolare del trattamento raccoglie e utilizza
                    queste informazioni, si veda il paragrafo sui cookie della presente Informativa sulla Privacy (9.
                    Informazioni relative ai cookie). Si noti che le informazioni personali possono anche essere
                    collegate ai cookie, ad es. per raccogliere informazioni sulle modalità di utilizzo del Sito e dei
                    servizi ivi offerti.<br/>
                    Il Titolare del trattamento potrebbe procedere alla raccolta automatica di alcuni dati personali
                    dell’Utente anche per capire in che modo quest’ultimo interagisce con il materiale di comunicazione
                    inviatogli dallo stesso Titolare del trattamento, ad esempio e-mail, incluse la azioni che compie in
                    relazione alle comunicazioni medesime, ad esempio i clic sui link presenti nel testo dell’e-mail, la
                    durata e la<br/>
                </p>
                <b>4. Perché vengono raccolti i dati personali?</b><br/>
                In termini generali, il Titolare del trattamento utilizza i dati personali per fornire i servizi
                richiesti dall’Utente, inviare comunicazioni di servizio, segnalare importanti modifiche al Sito ed
                eventualmente proporre contenuti, promozioni e offerte che il Titolare del trattamento ritenga possano
                essere di interesse per l’Utente.<br/>
                Più precisamente, i dati personali forniti dagli Utenti attraverso l’utilizzo del Sito, verranno
                trattati con il loro consenso, per le finalità di seguito descritte:<br/>
                <p>
                    <b>1. Erogazione dei servizi accessibili attraverso il Sito:</b><br/>
                    Al fine di erogare alcuni servizi quali ad esempio:<br/>
                    <i className="fa fa-circle"></i>&nbsp;creare e mantenere la relazione
                    contrattuale stabilita per la fornitura del Servizio richiesto in ogni sua fase e attraverso
                    qualsiasi possibile integrazione e/o modifica richiesta dall’Utente.<br/>
                    Su quali basi legali?<br/>
                    Per adempiere a un contratto o per l’esecuzione di un servizio o di misure collegate al contratto
                    e/o alla prestazione di un servizio (ovvero per fornire i servizi richiesti, e/o per fornire
                    assistenza all’Utente in ogni fase della relazione contrattuale)<br/>
                    <b>B. Rispetto dei requisiti legali, regolamentari e di conformità</b><br/>
                    Per soddisfare i requisiti legali, regolamentari e di conformità e per rispondere a richieste da
                    parte governativa o di autorità incaricate dell’applicazione della legge che stanno conducendo
                    un’indagine.<br/>
                    Su quali basi legali?<br/>
                    Per adempiere alla legge (ovvero per condividere dati personali con autorità regolamentari)<br/>
                    <b>C. Analisi statistiche integrative e di comportamento</b><br/>
                    Per svolgere analisi statistiche aggregative su gruppi anonimi o per analizzare il comportamento di
                    soggetti identificabili, in modo da potere vedere come vengono utilizzati il Sito, i servizi ivi
                    forniti e verificare le prestazioni della relativa attività.<br/>
                    Su quali basi legali?<br/>
                    Per perseguire il legittimo interesse del Titolare del trattamento (ovvero per migliorare il Sito,
                    le sue funzionalità e i servizi ivi offerti)<br/>
                    <b>D. Invio comunicazioni di marketing non personalizzate</b><br/>
                    Per inviare (nei casi consentiti dalla legge e salvo opposizione da parte dell’Utente) materiale
                    pubblicitario via e-mail oppure, nei casi consentiti dalla legge, altre comunicazioni elettroniche
                    equivalenti relative a servizi simili a quelli già richiesti e/o offerti sul Sito.<br/>
                    Su quali basi legali?<br/>
                    Soft Opt-in / Per perseguire il legittimo interesse del Titolare del trattamento (ovvero per fini di
                    marketing)<br/>
                    <b>E. Sicurezza del Sito e dei sistemi utilizzati dal Titolare del trattamento</b><br/>
                    Per mantenere la sicurezza del Sito e dei sistemi utilizzati dal Titolare del trattamento per la
                    fornitura dei Servizi e per prevenire e individuare eventuali frodi, incidenti a livello di
                    sicurezza e/o altri reati.<br/>
                    Su quali basi legali?<br/>
                    Per perseguire il legittimo interesse del Titolare del trattamento (ovvero per garantire la
                    sicurezza del Sito e dei sistemi)<br/>
                    <b>F. Verifica di conformità ed azioni giudiziarie</b><br/>
                    Per verificare la conformità alle Condizioni generali per l’utilizzo del Sito e per l’accertamento,
                    l’esercizio o la difesa di un diritto in sede giudiziaria.<br/>
                    Su quali basi legali?<br/>
                    Per perseguire il legittimo interesse del Titolare del trattamento (ovvero in conformità alle
                    Condizioni generali per l’utilizzo del Sito, per la protezione dei diritti del Titolare del
                    trattamento nell’eventualità di controversie o reclami)<br/>
                    <b>G. Personalizzazione inserzioni pubblicitarie e notifiche di marketing online</b><br/>
                    Per adattare e personalizzare inserzioni pubblicitarie e notifiche di marketing online in base alle
                    informazioni raccolte attraverso cookie e relative all’uso che l’Utente fa del Sito, dei servizi ivi
                    forniti nonchè di altri siti (per ulteriori informazioni si prega di fare riferimento al paragrafo
                    sui cookie della presente Informativa privacy).<br/>
                    Su quali basi legali?<br/>
                    Laddove l’Utente dia il proprio consenso (ovvero attraverso il Cookie banner o tramite le
                    impostazioni del browser)<br/>
                </p>
                <b>5. Chi vede, riceve e utilizza i dati e dove è possibile fare tutto ciò?</b><br/>
                <p>
                    <b>5.1. Categorie di destinatari dei dati</b><br/>
                    Il Titolare del trattamento condivide i dati personali, per le finalità descritte in questa
                    Informativa sulla Privacy, con le seguenti categorie di destinatari:<br/>
                    i suoi dipendenti e/o collaboratori
                    autorizzati che prestino servizi di assistenza e consulenza nelle aree amministrazione, prodotto,
                    consulenza legale, sistemi informatici, nonché al personale incaricato della manutenzione della rete
                    e delle attrezzature hardware e software del Titolare del trattamento;<br/>
                    le autorità competenti, qualora ci
                    sia prescritto dalle normative in vigore;<br/>
                    le autorità competenti e le autorità
                    terze incaricate dell’applicazione della legge, qualora ciò sia necessario al fine di fare applicare
                    le Condizioni generali per l’utilizzo del Sito nonché proteggere e difendere i diritti o la
                    proprietà del Titolare del trattamento o i diritti e la proprietà di terzi;<br/>
                    terzi che ricevono i dati (ad es.,
                    consulenti aziendali, professionisti nella fornitura di servizi di adeguata verifica in materia
                    fiscale, “due diligence” o che stimano il valore e le capacità dell’attività), qualora sia
                    necessario in relazione a vendite dell’attività o dei beni del Titolare del trattamento (eventualità
                    nella quale i dati verranno comunicati ai consulenti del Titolare del trattamento e ai consulenti di
                    qualsiasi potenziale compratore e verranno trasferiti ai nuovi proprietari).<br/>
                    i dati personali raccolti potranno
                    essere trattati anche da soggetti o categorie di soggetti che agiscono come responsabili del
                    trattamento dei dati ai sensi dell’art. 28 del Regolamento o che sono autorizzati al trattamento dei
                    dati ai sensi dell’art. 29 del Regolamento;<br/>
                    inoltre, per alcuni servizi, i dati
                    potranno essere comunicati a società che collaborano o utilizzano i servizi del Titolare del
                    trattamento con l'unico intento di erogare i servizi richiesti dall'Utente. In questi casi le
                    società sono autonome titolari del trattamento dei dati personali, pertanto il Titolare del
                    trattamento non è responsabile del trattamento dei dati da parte delle stesse. Il Titolare del
                    trattamento inoltre non è responsabile dei contenuti e del rispetto della normativa in tema di
                    protezione dei dati personali da parte di siti non gestiti dallo stesso.<br/>
                    L’elenco completo dei soggetti a cui i dati personali potranno essere comunicati è disponibile
                    presso la sede del Titolare del trattamento e può essere richiesto scrivendo a privacy@kibukibu.com.<br/>
                    <b>5.2. Trasferimento dei dati</b><br/>
                    Il trattamento dei dati personali dell’Utente avverrà presso la sede del Titolare del trattamento
                    (si veda Articolo 1 della presente Informativa sulla Privacy), sui server del Titolare stesso e
                    nelle sedi di eventuali altri soggetti a cui potrebbero essere trasmessi i dati con lo scopo di
                    prestare i servizi richiesti dall’Utente al Titolare del trattamento.<br/>
                    Inoltre, i dati personali raccolti tramite il Sito, potranno essere trasferiti al di fuori del
                    territorio nazionale (ma non al di fuori dello Spazio Economico Europeo), solo ed esclusivamente per
                    l'esecuzione dei servizi richiesti tramite il Sito e nel rispetto delle specifiche disposizioni
                    previste dal Regolamento.<br/>
                    Tuttavia, qualora l’Utente desideri ulteriori dettagli relativi alle misure di salvaguardia messe in
                    atto, è possibile contattare il Titolare del trattamento scrivendo a privacy@kibukibu.com.<br/>

                </p>
                <b>6. Modalità di trattamento e conservazione dei dati personali</b><br/>
                Il Titolare del trattamento assicura che i dati personali saranno trattati nel pieno rispetto del
                Regolamento, mediante sistemi manuali, informatici o telematici e, ove necessario, in formato cartaceo e
                saranno conservati nel database del Titolare del trattamento, tutelando la privacy e i diritti
                dell’Utente attraverso l'adozione di adeguate misure tecniche e organizzative per garantire un livello
                di sicurezza adeguato al rischio. Il trattamento potrà essere effettuato anche attraverso strumenti
                automatizzati atti a memorizzare, gestire e trasmettere i dati stessi.<br/>
                I dati raccolti ed oggetto di trattamento saranno protetti con metodologie fisiche e logiche tali da
                ridurre al minimo i rischi di accesso non consentito, diffusione, perdita e distruzione dei dati, ai
                sensi degli art. 25 e 32 del Regolamento.<br/>
                Ai sensi dell’art. 7 comma 3 del Regolamento, l’interessato ha diritto di ottenere in qualsiasi momento
                la revoca del consenso al trattamento dei propri dati personali.<br/>
                Qualora non pervenga al Titolare del trattamento una richiesta di cancellazione, i dati personali
                saranno conservati da quest’ultimo per tutto il tempo necessario a conseguire gli scopi e a compiere le
                attività descritte nella presente Informativa sulla Privacy, o come altrimenti comunicato all’Utente,
                oppure per il tempo consentito dalla normativa applicabile.<br/>
                Qui di seguito sono disponibili ulteriori informazioni relative al periodo di conservazione dei dati
                personali da parte del Titolare del trattamento:<br/>
                Dati utilizzati per finalità di marketing
                (dati utilizzati per attività di marketing non personalizzato verso gli Utenti) - Termine di
                conservazione: salvo opposizione da parte dell’Utente, 5 anni a partire dal soft opt-in o dal rinnovo
                del relativo consenso da parte dell’Utente attraverso l’interazione con comunicazioni di marketing.<br/>
                Riguardo invece ai dati personali raccolti mediante tag si applicano i seguenti termini di
                conservazione:<br/>
                Cookie tecnici – Termine di
                conservazione: massimo 3 anni, con decorrenza dalla data di navigazione sul Sito;<br/>
                Cookie non tecnici - Termine di
                conservazione: massimo 1 anno, con decorrenza dalla data del consenso dell'interessato.<br/><br/>
                <b>7. Quali sono i diritti alla protezione dei dati e come possono essere esercitati?</b><br/>
                È possibile esercitare i diritti garantiti dal Regolamento (articoli 15-22), inclusi i diritti a:<br/>
                Diritto di accesso: ricevere la conferma
                dell’esistenza dei dati personali, accedere al contenuto degli stessi e ottenerne una copia.<br/>
                Diritto di rettifica: aggiornare,
                rettificare e/o correggere i dati personali.<br/>
                Diritto alla cancellazione/diritto
                all’oblio e diritto di limitazione: richiedere la cancellazione dei dati o la limitazione dei dati che
                sono stati trattati in violazione della legge, inclusi quelli di cui non è necessaria l’archiviazione ai
                fini per cui i dati sono stati raccolti o trattati; qualora abbiamo reso pubblici i dati personali,
                l’Utente ha inoltre il diritto di richiedere la cancellazione dei dati personali e l’adozione di misure
                ragionevoli, anche tecniche, per informare gli altri titolari del trattamento che stanno trattando i
                dati personali della richiesta di cancellare qualsiasi link, copia o riproduzione di tali dati
                personali.<br/>
                Diritto alla portabilità dei dati:
                ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico una copia dei
                dati personali forniti al Titolare del trattamento ai fini di un contratto o con il consenso dell’Utente
                e di chiedere di trasferire tali dati personali a un altro titolare del trattamento.<br/>
                Diritto di revoca del consenso:
                nell’eventualità in cui il Titolare del trattamento dipenda dal consenso dell’Utente, quest’ultimo avrà
                sempre la possibilità di revocare tale consenso, sebbene il Titolare del trattamento possa disporre di
                altre basi giuridiche per il trattamento dei suddetti dati per altri scopi.<br/>
                Diritto di opposizione, in qualsiasi
                momento: diritto a opporsi in qualsiasi momento al trattamento dei dati personali in determinate
                circostanze (in particolare nei casi in cui non sia necessario trattare i dati per soddisfare requisiti
                contrattuali o legali, o qualora la Società utilizzi tali dati per attività di marketing diretto.<br/>
                Diritto di non essere sottoposto a una
                decisione basata unicamente sul trattamento automatizzato, compresa la profilazione: è sempre possibile
                richiedere che venga invece effettuato un processo decisionale manuale, esprimere la propria opinione o
                contestare decisioni basate unicamente sul trattamento automatizzato, compresa la profilazione, se tali
                decisioni producono effetti giuridici o altri effetti analoghi.<br/>
                <br/>
                È possibile esercitare tali diritti in qualsiasi momento nelle modalità seguenti:<br/>
                contattando il Titolare del
                trattamento via e-mail all’indirizzo privacy@kibukibu.com;<br/>
                per quanto concerne le attività di
                marketing diretto, si prega di notare che è possibile opporsi in qualsiasi momento facendo clic sul
                link di annullamento dell’iscrizione presente in ogni comunicazione inviata;<br/>
                per quanto riguarda le inserzioni
                pubblicitarie online e la relativa revoca del consenso, si prega di fare riferimento al paragrafo
                sui cookie della presente Informativa sulla Privacy.<br/>
                I diritti relativi ai dati personali
                possono essere limitati in alcune situazioni. Ad esempio, nel caso in cui soddisfare questa
                richiesta riveli i dati personali di un’altra persona o se vi sono requisiti legali o motivi
                legittimi cogenti, il Titolare del trattamento potrà proseguire il trattamento dei dati personali
                per cui è stata richiesta la cancellazione.<br/>
                Esiste inoltre il diritto di sporgere
                un reclamo qualora si ritenga che i propri dati personali siano stati gestiti scorrettamente. Si
                invita l’Utente a rivolgersi prima di tutto al Titolare del trattamento, ma è sua facoltà, nella
                misura in cui tale diritto si applichi al suo caso, sporgere reclamo direttamente all’autorità di
                controllo competente per la protezione dei dati.<br/>
                <br/>
                <b>8. Dati di contatto del Titolare del trattamento</b><br/>
                I dati di contatto del Titolare del trattamento dei dati di cui sopra sono:<br/>
                Alessandro Sangalli, C.F. SNGLSN80S30F704M – email:alessandros@kibukibu.com ed Alberto Noseda,
                C.F. NSDLRT75P28C933G – email:alberton@kibukibu.com.<br/><br/>
                <b>9. Informazioni relative ai cookie</b><br/>
                Per ogni informazione relativa ai cookie si prega di visitare la seguente <Link
                to="/legal/cookie-policy">pagina</Link>.<br/><br/>
                <b>10. Aggiornamento e versioni precedenti della presente Informativa sulla Privacy</b><br/>
                La presente Informativa sulla Privacy può subire modifiche nel tempo – anche connesse
                all'eventuale entrata in vigore di nuove normative di settore, all'aggiornamento o erogazione di
                nuovi servizi ovvero ad intervenute innovazioni tecnologiche. Pertanto, il Titolare del
                trattamento si riserva il diritto di modificare in qualsiasi momento la presente Informativa
                sulla Privacy conformemente a questo paragrafo. Qualora il Titolare del trattamento apporti
                modifiche alla presente Informativa sulla Privacy, pubblicherà sul Sito l’Informativa sulla
                Privacy revisionata ed inserirà la data di “ultimo aggiornamento” all’inizio della presente
                Informativa sulla Privacy.
            </p>
        </div>
    </LegalContainer>
</>
