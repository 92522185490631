import {selectorFamily} from "recoil";

export type ToyPrices = {
    price: number,
    timestamp: string
}
const nowString = new Date().toLocaleDateString()
const getShownToyPrices = (toyPrices: ToyPrices[]) => {
    if (toyPrices.length > 0) {
        const lastPrice = toyPrices[toyPrices.length -1]
        return lastPrice.timestamp !== nowString ? toyPrices.concat({price: lastPrice.price, timestamp: nowString}) : toyPrices
    }
    return toyPrices
};

export const getToyPrices = selectorFamily<ToyPrices[] | undefined, string>({
    key: "getToyPrices",
    get: (id) => async ({get}) => {
        if (id === "") return undefined

        const response = await fetch(`/api/v1/toy-prices/${id}`)
        const json: ToyPrices[] = await response.json()
        return getShownToyPrices(json.map(({price, timestamp}) => ({price: price / 100, timestamp: new Date(timestamp).toLocaleDateString()})))
    }
})

