import {Breadcrumb} from "../components/Breadcrumb/Breadcrumb";
import Robot from '../assets/about_robot.png'
import './aboutUs.scss'

export const AboutUs = () => <>
    <div className="aboutUsContainer">
        <Breadcrumb crumbs={["Home", "About us"]} />
        <h2>Concept</h2>
        <div className="aboutUsText">
            <div className="aboutUsImage">
                <img src={Robot} alt="Our concept"/>
            </div>
            <div className="aboutUsInnerText">
                <b>kibu(<span lang="ja-jp">基部</span>)</b>, nella<br />
                lingua Giapponese,<br />
                significa “base”,<br />
                “fondamenta”
            </div>
        </div>
        <h2>Mission</h2>
        <div className="aboutUsMission">
            Noi di kibukibu.com vogliamo rafforzare questo concetto, offrendo ai collezionisti un
            punto di riferimento: la base da cui partire ogni qual volta si è in cerca
            di action figure, robot, statue al miglior prezzo.
        </div>
    </div>


</>
